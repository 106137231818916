import React from 'react';

const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="svgicon" viewBox="0 0 512 512">
    <title>Home</title>
    <path
      d="M80 212v236a16 16 0 0016 16h96V328a24 24 0 0124-24h80a24 24 0 0124 24v136h96a16 16 0 0016-16V212"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="svgicon-fill-none svgicon-stroke-width"
    />
    <path
      d="M480 256L266.89 52c-5-5.28-16.69-5.34-21.78 0L32 256M400 179V64h-48v69"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="svgicon-fill-none svgicon-stroke-width"
    />
  </svg>
);

export default HomeIcon;
