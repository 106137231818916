import React, { Fragment, useState } from 'react';
import Lightbox from 'react-image-lightbox';

const InventoryTable = ({ inventoryData }) => {
  const [isLightBox, setIsLightBox] = useState(false),
    [clickedImg, setClickedImg] = useState('');
  return (
    <div className="product-details-table-no-deviation">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Item</th>
            <th scope="col">Name</th>
            <th scope="col">Barcode</th>
            <th scope="col">Qty</th>
          </tr>
        </thead>
        <tbody>
          {inventoryData &&
            inventoryData?.map((el, n) => (
              <Fragment key={n}>
                <tr>
                  <td>
                    <img
                      onClick={() => {
                        setClickedImg(el?.image);
                        setIsLightBox(true);
                      }}
                      src={el?.image}
                      className="inventoryTable-image"
                      alt="product"
                    />
                  </td>
                  <td>{el?.name}</td>
                  <td>{el?.barcode}</td>
                  <td>{el?.qty}</td>
                </tr>
                {isLightBox && (
                  <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
                )}
              </Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryTable;
