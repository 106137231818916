import React from 'react';

const PlusIcon = ({ fill = '#FE3D5E' }) => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Status / Plus">
      <path
        id="plus"
        d="M12.7511 7.81827H9.68288V4.75009C9.68288 4.57963 9.51243 4.40918 9.34197 4.40918H8.66016C8.46839 4.40918 8.31925 4.57963 8.31925 4.75009V7.81827H5.25107C5.0593 7.81827 4.91016 7.98873 4.91016 8.15918V8.841C4.91016 9.03276 5.0593 9.18191 5.25107 9.18191H8.31925V12.2501C8.31925 12.4419 8.46839 12.591 8.66016 12.591H9.34197C9.51243 12.591 9.68288 12.4419 9.68288 12.2501V9.18191H12.7511C12.9215 9.18191 13.092 9.03276 13.092 8.841V8.15918C13.092 7.98873 12.9215 7.81827 12.7511 7.81827Z"
        fill={fill}
      />
    </g>
  </svg>
);
export default PlusIcon;
