import React, { Fragment, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import LoadingOverlay from 'components/common/LoadingOverlay';
//api
import OrderDetailsService from 'services/OrderDetailsService';

const BasketModal = ({ isModalOpen, setIsOpenModal, basketId }) => {
  const [basketDetails, setBasketDetails] = useState(''),
    [isLightBox, setIsLightBox] = useState(false),
    [clickedImg, setClickedImg] = useState(''),
    [isLoaderOpen, setIsLoaderOpen] = useState(false);

  const fetchBasketDetails = async (basketId) => {
    setIsLoaderOpen(true);
    try {
      const res = await OrderDetailsService.getBasketDetails(basketId);
      setBasketDetails(res.data);
      setIsLoaderOpen(false);
    } catch (err) {
      setIsLoaderOpen(false);
    }
  };
  useEffect(() => {
    fetchBasketDetails(basketId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="basket-modal-wrapper">
      {isLoaderOpen && <LoadingOverlay />}
      {isModalOpen && (
        <Modal
          className="basket-modal"
          isOpen={isModalOpen}
          toggle={() => setIsOpenModal((prev) => !prev)}
        >
          <ModalBody>
            {basketDetails && basketDetails?.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Name</th>
                    <th scope="col">SKU</th>
                    <th scope="col">Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {basketDetails &&
                    basketDetails?.map((el, i) => (
                      <Fragment key={i}>
                        <tr>
                          <td>
                            <img
                              onClick={() => {
                                setClickedImg(el?.image);
                                setIsLightBox(true);
                              }}
                              src={el?.image}
                              className="basket-item-img"
                              alt="basket"
                            />
                          </td>
                          <td>{el?.name ?? ''}</td>
                          <td>{el?.sku ?? ''}</td>
                          <td>{el?.qty ?? ''}</td>
                        </tr>
                        {isLightBox && (
                          <Lightbox
                            mainSrc={clickedImg}
                            onCloseRequest={() => setIsLightBox(false)}
                          />
                        )}
                      </Fragment>
                    ))}
                </tbody>
              </table>
            ) : (
              <p className="empty-basket-text">Empty basket</p>
            )}
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default BasketModal;
