import React, { useRef, useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// Components
import CustomButton from 'headflorist/components/common/CustomButton';
import BackIcon from 'headflorist/assets/images/svg/BackIcon';
// Helpers
import { dataURLtoFile } from 'constants/Helpers';
// API
import OrderDetailsService from 'headflorist/services/OrderDetailsService';

const UploadQcImageModal = ({
  isModalOpen,
  setIsOpenModal,
  data,
  setData,
  setIsLoaderOpen,
  setFailedImg,
}) => {
  const videoRef = useRef(),
    captureRef = useRef(),
    drawRef = useRef(),
    [isCaptured, setIsCaptured] = useState(false),
    [tempImg, setTempImg] = useState('');

  const closeCamera = () => {
    const videoElement = videoRef.current;
    if (videoElement && videoElement.srcObject) {
      const stream = videoElement.srcObject;
      if (stream.getTracks) {
        stream.getTracks().forEach((track) => track.stop());
      } else if (stream.stop) {
        stream.stop();
      }
    }
  };

  const clearphoto = () => {
    const canvas = drawRef?.current;
    const photo = captureRef?.current;
    const context = canvas.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(0, 0, canvas.width, canvas.height);
    const data = canvas.toDataURL('image/png');
    photo.style.setProperty('background-image', `url(${data})`);
    setIsCaptured(false);
    videoRef.current.playsinline(true);
    videoRef.current.play();
  };

  const captureImage = () => {
    const canvas = drawRef?.current;
    const photo = captureRef?.current;
    const context = canvas.getContext('2d');
    const width = videoRef?.current?.videoWidth;
    const height = videoRef?.current?.videoHeight;
    if (width && height) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(videoRef?.current, 0, 0, width, height);
      const data = canvas.toDataURL('image/png');
      photo.style.setProperty('background-image', `url(${data})`);
      setTempImg(data);
      setIsCaptured(true);
    } else {
      clearphoto();
    }
  };

  const handleSaveImage = async () => {
    setIsLoaderOpen(true);
    let formData = new FormData();
    formData.append(`uploadProductionQcImage`, await dataURLtoFile(tempImg, 'image.png'));
    try {
      const res = await OrderDetailsService.uploadQcImage(formData);
      const updatedImages = [...data, { newImg: tempImg, imgName: res?.data }];
      setData(updatedImages);
    } catch (err) {
      setFailedImg(tempImg);
    } finally {
      closeCamera();
      setIsOpenModal((prev) => !prev);
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    setFailedImg('');
    const constraints = {
      audio: false,
      video: { facingMode: 'environment' },
    };

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        videoRef.current.srcObject = stream;
        videoRef.current.playsinline(true);
        videoRef.current.play();
      })
      .catch((error) => {
        return error;
      });

    return () => {
      // Cleanup: stop the video stream when the component is unmounted
      closeCamera();
    };
  }, []);

  return (
    <div className="upload-img-modal-wrapper">
      {isModalOpen && (
        <Modal
          className="upload-img-modal"
          isOpen={isModalOpen}
          toggle={() => {
            closeCamera();
            setIsOpenModal((prev) => !prev);
          }}
        >
          <ModalHeader>
            <div className={`header-wrapper no-bottom-margin`}>
              <div className="header-left-column">
                <div className="buttons-column">
                  <CustomButton
                    id="header-notifications"
                    handleClick={() => {
                      setIsOpenModal((prev) => !prev);
                      closeCamera();
                    }}
                    type="header-icon"
                    fontAwsome={<BackIcon />}
                  />
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <video
              className={`video-canvas ${isCaptured ? 'camera-hide' : 'camera-show'}`}
              ref={videoRef}
              autoPlay
              playsInline
              webkit-playsinline={true}
            ></video>
            <canvas className="draw-canvas" ref={drawRef}>
              {' '}
            </canvas>
            <div
              className={`capture-canvas ${isCaptured ? 'camera-show' : 'camera-hide'}`}
              ref={captureRef}
              alt=""
            ></div>
            <div className="img-fixed-footer">
              {isCaptured ? (
                <div className="buttons-column">
                  <CustomButton
                    id="retake-image"
                    handleClick={clearphoto}
                    type="retake-image"
                    name="Cancel"
                  />
                  <CustomButton
                    id="upload-image"
                    handleClick={handleSaveImage}
                    type="upload-image"
                    name="Upload"
                  />
                </div>
              ) : (
                <div className="capture-btn" onClick={captureImage}>
                  <div className="big-white-circle"></div>
                  <div className="black-ring"></div>
                  <div className="small-white-circle"></div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default UploadQcImageModal;
