import React from 'react';

const CameraIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Images / Camera">
      <path
        id="camera"
        d="M14.6875 9H18.5V18H5.5V9H9.28125L10.0312 7H13.9375L14.6875 9ZM14.125 5.5H10.0312C9.40625 5.5 8.84375 5.90625 8.625 6.5L8.25 7.5H5.5C4.65625 7.5 4 8.1875 4 9V18C4 18.8438 4.65625 19.5 5.5 19.5H18.5C19.3125 19.5 20 18.8438 20 18V9C20 8.1875 19.3125 7.5 18.5 7.5H15.75L15.2812 6.3125C15.0938 5.84375 14.6562 5.5 14.125 5.5ZM12 17.25C14.0625 17.25 15.75 15.5938 15.75 13.5C15.75 11.4375 14.0625 9.75 12 9.75C9.90625 9.75 8.25 11.4375 8.25 13.5C8.25 15.5938 9.90625 17.25 12 17.25ZM12 11.25C13.2188 11.25 14.25 12.2812 14.25 13.5C14.25 14.75 13.2188 15.75 12 15.75C10.75 15.75 9.75 14.75 9.75 13.5C9.75 12.2812 10.75 11.25 12 11.25Z"
        fill="#C0CCD9"
      />
    </g>
  </svg>
);

export default CameraIcon;
