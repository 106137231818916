import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import AppErrorBoundary from 'components/common/AppErrorBoundary';
import { getLoginPageUrl, getHomePageUrl } from 'constants/AppUrls';
import { hasPermission } from 'constants/Helpers';
// selectors
import {
  getIsLoggedIn,
  getIsLoggedoutClicked,
  getPermissionsData,
  getIsPermissionsLoaded,
} from 'store/app/AppSelectors';

const RestrictedRoute = ({ component: Component, requiredPermissions, page, ...rest }) => {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const isLoggedoutClicked = useSelector((state) => getIsLoggedoutClicked({ state }));
  const permissions = useSelector((state) => getPermissionsData({ state }));
  const isPermissionsLoaded = useSelector((state) => getIsPermissionsLoaded({ state }));
  const location = useLocation();
  const [isPermissionsFetched, setIsPermissionsFetched] = useState(false);

  useEffect(() => {
    if (isPermissionsLoaded) {
      setIsPermissionsFetched(true);
    }
  }, [isPermissionsLoaded]);

  if (!isPermissionsFetched && isLoggedIn) {
    return null;
  }

  if (isLoggedIn) {
    if (
      window.location.pathname.indexOf('production') > 0 &&
      !hasPermission(permissions, 'florist_production', 'view')
    ) {
      return (
        <Navigate
          to={{
            pathname: getHomePageUrl(),
            state: { from: location },
          }}
        />
      );
    } else {
      return (
        <AppErrorBoundary>
          <Component {...rest} />
        </AppErrorBoundary>
      );
    }
  } else {
    return (
      <Navigate
        to={{
          pathname:
            window.location.pathname !== getLoginPageUrl() && !isLoggedoutClicked
              ? `${getLoginPageUrl()}`
              : getLoginPageUrl(),
          search:
            window.location.pathname !== getLoginPageUrl() && !isLoggedoutClicked
              ? `?url-source=${window.location.href}${location.search ? `&${location.search}` : ''}`
              : location.search,
          state: { from: location },
        }}
      />
    );
  }
};

export default RestrictedRoute;
