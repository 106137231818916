export const endPoints = {
  // permission
  getUserPermission: `user/Userdetails/florist-actions`,
  // orders
  getTimeSlotByDate: `slots`,
  ordersListData: `slots/{0}/orders`,
  getFloristsList: ``,
  orderDetails: `order/{0}/details`,
  cartDetails: `cart/{0}/details`,
  inventoryDetails: `order/v2/florist/map/{0}/product-inventory-details/{1}/{2}`,
  basketDetails: `cart/{0}/basket-details`,
  getFavFlowersData: `catalog/hub/SubscriptionFavorites/{0}`,
  login: `user/UserDetails/outsource/login`,
  session: `user/authentication/sso/{0}/session`,
  country: `home/country`,
  completedOrders: `slots/0/orders`,
  assignFlorist: `assign/{0}`,
  changeOrderPreparationStatus: `change-order-preparation/{0}`,
  notDoneReasonsList: `cancel-preparation-reasons`,
  // group by
  groupedBy: `slots/{0}/orders/grouped`,
  // notifications
  getNotificationsList: `notifications`,
  getReadNotification: `notifications/{0}`,
  getProceedAck: `notification/{0}/done`,
  getRejectAck: `map/{0}/inventory-deviations`,
  // Production
  getUnassignedBatches: `prodapp/florist/unassigned-batches`,
  // Deviation
  getDeviationReasons: `inventory-deviation-reasons`,
  addDeviation: `map/{0}/inventory-deviations/{1}/{2}`,
  editDeviation: `map/{0}/change/inventory-deviations/{1}/{2}`,
  //Quality check
  uploadQcImage: `qc`,
  getQcImages: `order/v2/florist/production-qc/{0}`,
};
