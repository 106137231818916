import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
//api
import OrderDetailsService from 'services/OrderDetailsService';

const setOrderDetails = (orderId) => async (dispatch) => {
  try {
    const res = await OrderDetailsService.getOrderDetails(orderId);
    dispatch({
      type: 'SET_ORDER_DETAILS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setCartDetails = (orderId) => async (dispatch) => {
  try {
    const res = await OrderDetailsService.getCartDetails(orderId);
    dispatch({
      type: 'SET_CART_DETAILS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setOrderDetailsAllData = (orderId) => async (dispatch) => {
  let promisesArray = [];
  dispatch(setLoaderOpenTrue());
  try {
    promisesArray.push(dispatch(setOrderDetails(orderId)), dispatch(setCartDetails(orderId)));
    await Promise.all(promisesArray);
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    dispatch(setLoaderOpenFalse());
  }
};
