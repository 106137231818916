import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { colleagueStatus, status } from 'constants/Helpers';
// SVG
import StarIcon from 'assets/images/svg/StarIcon';
// Components
import ChangeStatusBtn from 'components/orders/ChangeStatusBtn';
// Actions
import { setOrderDetailsAllData } from 'store/orderDetails/OrderDetailsActions';

const OrderDetailsCard = ({ orderDetails }) => {
  const dispatch = useDispatch();

  const fetchOrderDetails = (orderId) => {
    dispatch(setOrderDetailsAllData(orderId));
  };

  return (
    <div className="card orderDetails-card">
      <div className="card-body">
        <div className="order-slot-col">
          <h6 className="delivery-date">
            {moment(orderDetails?.deliveryDate).format('DD/MM/YYYY')}
          </h6>
          <h6 className="delivery-slot">{orderDetails?.deliveryTimeName}</h6>
        </div>
        <div className="order-id-col">
          <h6 className="order-id-text">Order</h6>
          <h6 className="order-id-value">{orderDetails?.orderId}</h6>
          {orderDetails?.productionLine === 1 && <StarIcon />}
          {orderDetails?.nwDelivery ? <div className="nw-order">NW</div> : null}
        </div>
        {orderDetails?.deliveryZone ? (
          <div className="delivery-zone-col">
            <h6 className="zone-text">Zone</h6>
            <h6 className="zone-value">{orderDetails?.deliveryZone}</h6>
          </div>
        ) : null}
        {orderDetails?.note ? (
          <div className="order-notes-col">
            <h6 className="notes-text">Note</h6>
            <h6 className="notes-value">{orderDetails?.note ?? ''}</h6>
          </div>
        ) : null}
        {orderDetails?.florist && orderDetails?.florist?.name && (
          <div className="assign-florist-col">
            <h6 className="florist-name">{orderDetails?.florist?.name ?? ''}</h6>
            <h6 className="florist-status">
              {orderDetails?.preparationStatus
                ? colleagueStatus[orderDetails?.preparationStatus]
                : ''}
            </h6>
          </div>
        )}
        <div
          className={`order-status-col ${
            Array.isArray(status[orderDetails?.preparationStatus ?? 6])
              ? 'order-status-col-btns'
              : ''
          }`}
        >
          <ChangeStatusBtn item={orderDetails} fetchMoreData={fetchOrderDetails} details={true} />
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
