import React from 'react';

const RightCaret = () => {
  return (
    <svg viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="right-caret">
      <path
        d="M1.5 1.5L12 12L1.5 22.5"
        stroke="#6B7280"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightCaret;
