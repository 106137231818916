import React from 'react';

const DeleteIcon = ({ fill = '#FE3D5E' }) => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Editors / Trash Alt">
      <path
        id="trash-alt"
        d="M9.93679 11.9095H10.4482C10.576 11.9095 10.7038 11.803 10.7038 11.6539V7.05158C10.7038 6.92374 10.576 6.7959 10.4482 6.7959H9.93679C9.78764 6.7959 9.68111 6.92374 9.68111 7.05158V11.6539C9.68111 11.803 9.78764 11.9095 9.93679 11.9095ZM13.4311 4.75044H11.6626L10.9382 3.55726C10.7678 3.25897 10.4268 3.0459 10.0646 3.0459H7.91264C7.55043 3.0459 7.20952 3.25897 7.03906 3.55726L6.31463 4.75044H4.56747C4.37571 4.75044 4.22656 4.9209 4.22656 5.09135V5.43226C4.22656 5.62402 4.37571 5.77317 4.56747 5.77317H4.90838V12.9323C4.90838 13.5075 5.35582 13.955 5.93111 13.955H12.0675C12.6214 13.955 13.0902 13.5075 13.0902 12.9323V5.77317H13.4311C13.6016 5.77317 13.772 5.62402 13.772 5.43226V5.09135C13.772 4.9209 13.6016 4.75044 13.4311 4.75044ZM7.87003 4.13255C7.89134 4.11124 7.93395 4.06863 7.97656 4.06863C7.97656 4.06863 7.97656 4.06863 7.99787 4.06863H10.0007C10.0433 4.06863 10.0859 4.11124 10.1072 4.13255L10.4695 4.75044H7.50781L7.87003 4.13255ZM12.0675 12.9323H5.93111V5.77317H12.0675V12.9323ZM7.55043 11.9095H8.06179C8.18963 11.9095 8.31747 11.803 8.31747 11.6539V7.05158C8.31747 6.92374 8.18963 6.7959 8.06179 6.7959H7.55043C7.40128 6.7959 7.29474 6.92374 7.29474 7.05158V11.6539C7.29474 11.803 7.40128 11.9095 7.55043 11.9095Z"
        fill={fill}
      />
    </g>
  </svg>
);
export default DeleteIcon;
