import React from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import AppErrorBoundary from './AppErrorBoundary';

const CustomSelect = ({
  id,
  listData,
  handleChange,
  placeholder,
  textPropName,
  valuePropName,
  disabled = false,
  selectedValue = -1,
  iconCss,
  groupBy,
  template,
  onOpen,
  allowFiltering = true,
  delayUpdate,
  valueTemplate,
  forwardedRef,
  showClearButton,
  handleClear,
  selectName,
  label,
}) => {
  const onFiltering = (e) => {
    let query = new Query();
    query = e.text !== '' ? query.where(textPropName, 'contains', e.text, true) : query;
    e.updateData(listData, query);
  };

  const changeFunction = (e) => {
    if (selectName) {
      handleChange(e, selectName);
    } else {
      handleChange(e);
    }
  };

  return (
    <div className="filter-select">
      <AppErrorBoundary>
        <div className="control-pane">
          <div className="control-section">
            <div id="filtering" className={`${label ? 'filtering-with-label' : ''}`}>
              {label && <p className="custom-select-label">{label}</p>}
              <DropDownListComponent
                id={id ?? 'country'}
                htmlAttributes={{ autocomplete: 'off' }}
                ref={forwardedRef}
                dataSource={listData}
                filtering={onFiltering}
                change={(e) => changeFunction(e)}
                filterBarPlaceholder={placeholder}
                allowFiltering={allowFiltering}
                fields={{
                  text: textPropName,
                  value: valuePropName,
                  iconCss: iconCss ?? null,
                  groupBy: groupBy ?? null,
                }}
                placeholder={placeholder}
                popupHeight="220px"
                popupWidth="150px"
                enabled={!disabled}
                value={selectedValue}
                itemTemplate={template ? (data) => template(data) : null}
                open={onOpen}
                delayUpdate={delayUpdate}
                valueTemplate={valueTemplate}
              />
              {showClearButton && (
                <i className="icon-close custom-select-close" onClick={handleClear}></i>
              )}
            </div>
          </div>
        </div>
      </AppErrorBoundary>
    </div>
  );
};

export default CustomSelect;
