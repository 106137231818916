const CustomButton = ({
  id,
  name,
  handleClick,
  disabled,
  type,
  cssClasses,
  icon,
  iconCss,
  fontAwsome,
  style,
}) => {
  return (
    <button
      className={`custom-button custom-btn-${type} ${cssClasses ? cssClasses : ''}`}
      id={id}
      onClick={handleClick}
      disabled={disabled}
      style={style ?? null}
    >
      {icon ? <img className={iconCss} src={icon} alt="button icon" /> : null}
      {fontAwsome ?? null}
      {name ?? null}
    </button>
  );
};

export default CustomButton;
