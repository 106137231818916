import React, { useState } from 'react';
// Components
import LoadingOverlay from 'components/common/LoadingOverlay';
import CustomButton from 'components/common/CustomButton';
import { toast } from 'react-toastify';
// Helpers
import { status } from 'constants/Helpers';
// API
import OrdersListService from 'services/OrdersListService';

const ChangeStatusBtn = ({ item, initialParams, fetchMoreData, details }) => {
  const [isLoaderOpen, setIsloaderOpen] = useState(false);

  const fetchData = () => {
    if (details) {
      fetchMoreData(item?.orderId);
    } else {
      fetchMoreData(initialParams, 1);
    }
  };

  const handleChangeStatus = async (item) => {
    setIsloaderOpen(true);
    try {
      if (item?.preparationStatus === 3 || item?.preparationStatus === 7) {
        await OrdersListService.assignFloristStatus(item?.orderId, 'start');
        setTimeout(() => fetchData(), 1000);
      } else if (item?.preparationStatus === 2) {
        await OrdersListService.assignFloristStatus(item?.orderId, 'finish');
        setTimeout(() => fetchData(), 500);
      } else if (
        !item?.preparationStatus ||
        item?.preparationStatus === 6 ||
        item?.preparationStatus === 1
      ) {
        await OrdersListService.assignFloristStatus(item?.orderId, 'assign');
        setTimeout(() => fetchData(), 1000);
      }
      setIsloaderOpen(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Status change failed!');
      setIsloaderOpen(false);
    }
  };

  const handleNotDone = async () => {
    setIsloaderOpen(true);
    try {
      await OrdersListService.assignFloristStatus(item?.orderId, 'NotDone');
      setTimeout(() => fetchData(), 1000);
      setIsloaderOpen(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Status change failed!');
      setIsloaderOpen(false);
    }
  };
  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      {typeof status[item?.preparationStatus ?? 6] === 'string' ? (
        <CustomButton
          handleClick={() => handleChangeStatus(item)}
          type={`status ${item?.preparationStatus === 0 ? 'success' : 'primary'} ${
            item?.preparationStatus === 0 ? 'hidden' : ''
          }`}
          disabled={item?.preparationStatus === 4}
          name={status[item?.preparationStatus ?? 6]}
        />
      ) : Array.isArray(status[item?.preparationStatus ?? 6]) ? (
        status[item?.preparationStatus ?? 6]?.map((s) => {
          return (
            <CustomButton
              handleClick={
                s === 'Done' ? () => handleChangeStatus(item) : () => handleNotDone(item)
              }
              type={`status ${s === 'Done' ? 'success' : 'danger'} ${
                item?.preparationStatus === 0 ? 'hidden' : ''
              }`}
              name={s}
              key={s}
            />
          );
        })
      ) : (
        <CustomButton
          handleClick={() => handleChangeStatus(item)}
          type={`status secondary ${item?.preparationStatus === 0 ? 'hidden' : ''}`}
          disabled={!status[item?.preparationStatus ?? 6].enabled}
          name={status[item?.preparationStatus ?? 6].text}
        />
      )}
    </>
  );
};

export default ChangeStatusBtn;
