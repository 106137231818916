import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OrdersListService {
  static getordersListData(data, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.ordersListData, ...extraParams),
      params: data,
    });
  }

  static getFloristsList(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFlorsitsList, ...extraParams),
    });
  }

  static getCompletedOrders(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.completedOrders, ...extraParams),
      params,
    });
  }

  static assignFloristStatus(...extraParams) {
    return apiGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.assignFlorist, ...extraParams),
    });
  }

  static getGroupedBySku(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.groupedBySku, ...extraParams),
      params,
    });
  }

  static getGroupedByZone(params, ...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.groupedByZone, ...extraParams),
      params,
    });
  }
}
export default OrdersListService;
