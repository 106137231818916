import React, { Fragment, useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
//api
import OrderDetailsService from 'services/OrderDetailsService';

const FavoriteFlowers = ({ cartId }) => {
  const [isLightBox, setIsLightBox] = useState(false),
    [clickedImg, setClickedImg] = useState(''),
    [favFlowersData, setFavFlowersData] = useState([]);

  const fetchFavFlowers = async (id) => {
    try {
      const res = await OrderDetailsService.getFavoriteFlowersData(id);
      setFavFlowersData(res?.data);
    } catch (err) {
      console.log('failed');
    }
  };

  useEffect(() => {
    fetchFavFlowers(cartId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {favFlowersData?.length > 0 && (
        <div className="fav-flowers-table">
          <div className="fav-flowers-title">Favorite Flowers</div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Image</th>
              </tr>
            </thead>
            <tbody>
              {favFlowersData?.map((el, n) => (
                <Fragment key={n}>
                  <tr>
                    <td>{el?.name}</td>
                    <td>
                      <img
                        onClick={() => {
                          setClickedImg(el?.fullImage);
                          setIsLightBox(true);
                        }}
                        src={el?.fullImage}
                        className="fav-flower-img"
                        alt="flower"
                      />
                    </td>
                    <td>{el?.qty}</td>
                  </tr>
                  {isLightBox && (
                    <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default FavoriteFlowers;
