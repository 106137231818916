export const getIsLoggedIn = ({ state }) => state.app.isLoggedIn;
export const getIsLoaderOpen = ({ state }) => state.app.isLoaderOpen;

export const getNotificationsList = ({ state }) => state.app.notificationsList;
export const getNotificationsLength = ({ state }) => state.app.notificationsLength;
export const getIsNotificationsAlertOpen = ({ state }) => state.app.isNotificcationAlertOpen;

export const getIsLoggedoutClicked = ({ state }) => state.app.isLoggedoutClicked;

export const getPermissionsData = ({ state }) => state.app.permissionsList;
export const getIsPermissionsLoaded = ({ state }) => state.app.isLoadingPermissions;

export const getHeadFloristEnabled = ({ state }) => state.app.headFloristEnabled;

export const getFirebasePermissions = ({ state }) => state.app.firebasePermissions;
