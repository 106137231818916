import { updateObject } from '../Utility';

const initialState = {
  isLoggedIn: !!localStorage.getItem('token'),
  isLoaderOpen: false,
  notificationsList: [],
  notificationsLength: 0,
  isNotificcationAlertOpen: false,
  isLoggedoutClicked: false,
  permissionsList: [],
  isLoadingPermissions: false,
  headFloristEnabled: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_IS_LOGGED_IN_TRUE': {
      return updateObject(state, { isLoggedIn: true });
    }
    case 'SET_IS_LOGGED_IN_FALSE': {
      localStorage.removeItem('opsId');
      return updateObject(state, { isLoggedIn: false });
    }
    case 'SET_LOADER_OPEN_TRUE': {
      return updateObject(state, { isLoaderOpen: true });
    }
    case 'SET_LOADER_OPEN_FALSE': {
      return updateObject(state, { isLoaderOpen: false });
    }
    case 'SET_NOTIFICATIONS_LIST': {
      return updateObject(state, { notificationsList: action.payload });
    }
    case 'SET_NOTIFICATIONS_LENGTH': {
      return updateObject(state, { notificationsLength: action.payload });
    }
    case 'SET_TOGGLE_NOTIFICATIONS_ALERT': {
      return updateObject(state, { isNotificcationAlertOpen: !state.isNotificcationAlertOpen });
    }
    case 'SET_IS_LOGGED_OUT_CLICKED': {
      return updateObject(state, { isLoggedoutClicked: true });
    }
    case 'SET_PERMISSIONS': {
      return updateObject(state, { permissionsList: action.payload, isLoadingPermissions: true });
    }
    case 'SET_PERMISSIONS_LOADED': {
      return updateObject(state, { isLoadingPermissions: false });
    }
    case 'SET_IS_HEAD_FLORIST_TRUE': {
      return updateObject(state, { headFloristEnabled: true });
    }
    case 'SET_IS_HEAD_FLORIST_FALSE': {
      return updateObject(state, { headFloristEnabled: false });
    }
    case 'SET_NEW_RECEIVED_NOTIFICATION': {
      const notificationsList = [...state.notificationsList];
      const notificationsLength = state.notificationsLength + 1;
      notificationsList.unshift(action.payload);
      return updateObject(state, { notificationsList, notificationsLength });
    }
    case 'SET_FIREBASE_PERMISSIONS': {
      return updateObject(state, { firebasePermissions: action.payload });
    }
    default:
      return state;
  }
};

export default reducer;
