import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';
// Components
import CustomButton from 'headflorist/components/common/CustomButton';
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
// Helpers
import { preparationTypes } from 'constants/Helpers';
// Selectors
import { getFloristsList } from 'headflorist/store/orders/OrdersListSelectors';
// API
import OrdersListService from 'headflorist/services/OrdersListService';
// Localstorage
import { getFromLocalStorage } from 'headflorist/helpers/LocalStorage';

const AssignOrderModal = ({ isModalOpen, setIsOpenModal, orderData, fetchData }) => {
  const floristsList = useSelector((state) => getFloristsList({ state }));
  const [selectedFlorist, setSelectedFlorist] = useState(-1);
  const [isLoaderOpen, setIsloaderOpen] = useState(false);

  const handleAssignToMe = async () => {
    setIsloaderOpen(true);
    try {
      await OrdersListService.assignFloristStatus(
        {
          assigneeId:
            getFromLocalStorage('floristId') ?? jwt_decode(getFromLocalStorage('token'))?.id,
        },
        orderData?.id
      );
      setIsOpenModal(false);
      setTimeout(() => fetchData(), 1000);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Status change failed!');
      setIsloaderOpen(false);
    }
  };

  const handleAssignToOther = async () => {
    setIsloaderOpen(true);
    try {
      await OrdersListService.assignFloristStatus({ assigneeId: selectedFlorist }, orderData?.id);
      setIsOpenModal(false);
      setTimeout(() => fetchData(), 1000);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Status change failed!');
      setIsloaderOpen(false);
    }
  };

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      <Modal
        className="assign-modal"
        isOpen={isModalOpen}
        toggle={() => setIsOpenModal((prev) => !prev)}
      >
        <ModalHeader>
          Assign order <span className="order-number">"{orderData?.id}"</span>
        </ModalHeader>
        <ModalBody>
          <div className="assign-container">
            <div className="assign-col-6">
              <ul>
                {orderData?.cartData?.map((item) => {
                  let currentPrepType = preparationTypes.find(
                    (pt) => parseInt(pt.id, 10) === parseInt(item?.preparationType, 10)
                  );
                  return (
                    <li key={item?.id} className="product-card">
                      <div className="product-card-img">
                        <img src={item?.productImageFullPath} alt={item?.proName} />
                      </div>
                      <div className="product-card-content">
                        <h3>
                          <b>{item?.qty}X</b> - {item?.proName}
                        </h3>
                        <div
                          className="prepType-btn"
                          style={{
                            backgroundColor: currentPrepType?.bgColor,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: currentPrepType?.textColor,
                            }}
                          >
                            {currentPrepType?.name}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="assign-col-6">
              <ul className="flw-list">
                {floristsList?.map((f) => (
                  <li className="flw-item" key={f?.id}>
                    <label htmlFor={f?.id}>{f?.name}</label>
                    <div className="florist-order-count">
                      <p>{f?.orderCount}</p>
                    </div>
                    <div className="custom-radio-container">
                      <input
                        type="radio"
                        id={f?.id}
                        name="user"
                        value={f?.id}
                        onChange={() => setSelectedFlorist(f?.id)}
                      />
                      <div className="custom-radio"></div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="flex-footer">
            <div className="fixed-footer">
              <CustomButton
                id={'modal-assign-btn'}
                name={'Assign to me'}
                handleClick={() => handleAssignToMe()}
                disabled={isLoaderOpen}
                type={'assignToMe'}
              />
            </div>
            <div className="fixed-footer">
              <CustomButton
                id={'modal-cancel-btn'}
                name={'Cancel'}
                handleClick={() => setIsOpenModal(false)}
                disabled={false}
                type={'cancel'}
              />
              <CustomButton
                id={'modal-assign-btn'}
                name={'Assign'}
                handleClick={() => handleAssignToOther()}
                disabled={isLoaderOpen}
                type={'assign'}
              />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AssignOrderModal;
