import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import AuthService from 'headflorist/services/AuthService';
import { getFromLocalStorage } from 'helpers/LocalStorage';
//api
import NotificationsService from 'headflorist/services/NotificationsService';
//selectors
import { getNotificationsList } from './AppSelectors';

export const setIsLoggedInTrue = () => ({ type: 'SET_IS_LOGGED_IN_TRUE' });
export const setIsLoggedInFalse = () => ({ type: 'SET_IS_LOGGED_IN_FALSE' });

export const setLoaderOpenTrue = () => ({ type: 'SET_LOADER_OPEN_TRUE' });
export const setLoaderOpenFalse = () => ({ type: 'SET_LOADER_OPEN_FALSE' });

export const setNotificationsList = () => async (dispatch) => {
  try {
    const res = await NotificationsService.getNotificationsList();
    const notificationsLength = res?.data?.filter((el) => el?.read === false)?.length;
    dispatch({
      type: 'SET_NOTIFICATIONS_LIST',
      payload: res?.data,
    });
    dispatch({
      type: 'SET_NOTIFICATIONS_LENGTH',
      payload: notificationsLength,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setReadNotification = (id) => async (dispatch, getState) => {
  const state = getState(),
    notificationsList = cloneDeep(getNotificationsList({ state }));
  try {
    await NotificationsService.getReadNotification(id);
    notificationsList.find((item) => item?.id === id).read = true;
    const notificationsLength = notificationsList.filter((el) => el?.read === false)?.length;
    dispatch({
      type: 'SET_NOTIFICATIONS_LIST',
      payload: notificationsList,
    });
    dispatch({
      type: 'SET_NOTIFICATIONS_LENGTH',
      payload: notificationsLength,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setToggleNotificationAlert = () => async (dispatch) => {
  dispatch({
    type: 'SET_TOGGLE_NOTIFICATIONS_ALERT',
  });
};

export const logoutFlorist = () => async (dispatch) => {
  dispatch({ type: 'SET_IS_LOGGED_OUT_CLICKED' });
};

export const getPermissions = () => async (dispatch) => {
  try {
    const res = await AuthService.getPermissions();
    dispatch({
      type: 'SET_PERMISSIONS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.message ?? 'Failed to fetch permissions!');
  }
};

export const setWebSocketConnection = () => async (dispatch) => {
  let newSocket;

  const connectWebSocket = () => {
    const token = `Bearer ${getFromLocalStorage('token')}`;
    const opsId = getFromLocalStorage('opsId');
    newSocket = new WebSocket(
      `${process.env.REACT_APP_WEB_SOCKET_API}?token=${token}&opsId=${opsId}`
    );

    newSocket.onopen = (event) => {
      console.log('WebSocket connection opened:', event);
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event?.data);
      dispatch({
        type: 'SET_NEW_RECEIVED_NOTIFICATION',
        payload: data,
      });
    };

    newSocket.onclose = (event) => {
      connectWebSocket();
    };

    newSocket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  };
  connectWebSocket();
};

export const setProceedAckMesaage =
  (mapId, setIsBtnDisabled, setIsModalOpen, type) => async (dispatch) => {
    setIsBtnDisabled(true);
    try {
      const res =
        type === 'proceed'
          ? await NotificationsService.getProceedAck(mapId)
          : await NotificationsService.getRejectAck(mapId);
      setIsBtnDisabled(false);
      setIsModalOpen(false);
      toast.success(res?.data?.message);
      setTimeout(() => {
        dispatch(setNotificationsList());
      }, 1500);
    } catch (err) {
      setIsBtnDisabled(false);
      setIsModalOpen(false);
      toast.error(err?.response?.data?.message);
    }
  };
