import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OrderDetailsService {
	static getOrderDetails(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.orderDetails, ...extraParams),
		});
	}

	static getCartDetails(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.cartDetails, ...extraParams),
		});
	}

	static getInventoryDetails(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.inventoryDetails, ...extraParams),
		});
	}

	static getBasketDetails(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.basketDetails, ...extraParams),
		});
	}

	//favorite flowers
	static getFavoriteFlowersData(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.getFavFlowersData, ...extraParams),
		});
	}
}
export default OrderDetailsService;
