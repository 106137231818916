import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Helpers
import { colleagueStatus, convertQueryParamsIntoObject } from 'constants/Helpers';
// Components
import ChangeStatusBtn from 'components/orders/ChangeStatusBtn';
import StarIcon from 'assets/images/svg/StarIcon';
// Selectors
import { getGroupBySkuCurrentPage, getGroupBySkuPageCount } from 'store/orders/OrdersListSelectors';
// Actions
import { setGroupBySkuData } from 'store/orders/OrdersListActions';

const BySku = ({ groupBySkuData, navigate, onStatusChanged, onScreen, isLoaderOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = useSelector((state) => getGroupBySkuCurrentPage({ state }));
  const pageCount = useSelector((state) => getGroupBySkuPageCount({ state }));

  useEffect(() => {
    if (onScreen && !isLoaderOpen) {
      if (currentPage < pageCount) {
        const queryObj = convertQueryParamsIntoObject(location.search);
        dispatch(setGroupBySkuData(queryObj, currentPage + 1));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return (
    <div className="orderWrapper">
      {groupBySkuData?.length > 0 ? (
        <>
          {groupBySkuData?.map((item, i) => (
            <div className="card orderCard" key={i}>
              <div className="card-header orderHeader">
                <div className="assign-florist">
                  <img alt="product" className="product-img" src={item?.productImage} />
                </div>
                <div className="assign-florist">
                  <h6 className="mb-0 sku">SKU {item?.productSku}</h6>
                </div>
                <div className="assign-florist">
                  {' '}
                  <h6 className="mb-0 product-name">{item?.productName}</h6>
                </div>
              </div>
              <div className="card-body">
                <div className="card-body-content">
                  {item?.ordersList?.map((el, n) => (
                    <div key={n} className="order-list">
                      <div className="assign-florist">
                        <h6 className="mb-0 order">
                          Order{' '}
                          {el?.orderPriority === 1 ? <span className="order-priority">!</span> : ''}
                        </h6>
                        <h6
                          onClick={() => navigate(`/orderdetails/${el?.orderId}`)}
                          className="order-id"
                        >
                          {el?.orderId}
                        </h6>
                        {el?.productionLine === 1 && <StarIcon cssClass="star-icon" />}
                        {el?.membershipType === 1 && <div className="vip-order">VIP</div>}
                      </div>
                      <div className="assign-florist">
                        <h6 className="mb-0">Zone</h6>
                        <h6>{el?.deliveryZone && el?.deliveryZone}</h6>
                      </div>
                      <div className="assign-florist">
                        <h6 className="mb-0 florist-name">{el?.floristName && el?.floristName}</h6>
                        <h6 className="prep-status">
                          {el?.preparationStatus && colleagueStatus[el?.preparationStatus]}
                        </h6>
                      </div>
                      <div className="statusBtn">
                        <ChangeStatusBtn
                          item={el}
                          onSuccess={onStatusChanged}
                          index={i}
                          subIndex={n}
                          type="status"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <h4 className="no-orders-title">No orders</h4>
      )}
    </div>
  );
};

export default BySku;
