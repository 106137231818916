import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import CustomButton from 'components/common/CustomButton';
import { toast } from 'react-toastify';
// API
import OrdersListService from 'services/OrdersListService';
// Actions
import { setOrdersListData } from 'store/orders/OrdersListActions';

const Florists = ({ setCounter, initialParams, slotId }) => {
  const [floristsList, setFloristsList] = useState([]);
  const [activeButton, setActiveButton] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchFlorists = async () => {
    try {
      const res = await OrdersListService.getFloristsList();
      setFloristsList(res?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Failed to fetch florists!');
    }
  };
  useEffect(() => {
    fetchFlorists();
  }, []);

  const handleFloristOrders = (floristId) => {
    setActiveButton(floristId);
    dispatch({
      type: 'SET_ORDERS_LIST_DATA',
      payload: {
        ordersList: [],
        pageCount: 1,
        isFlorist: true,
      },
    });
    setCounter(1);
    dispatch(
      setOrdersListData({ ...initialParams, page: 1, floristId: floristId }, slotId, navigate)
    );
  };

  return (
    <>
      <div className="florists-container">
        <CustomButton
          name={'All'}
          type="florists"
          cssClasses={activeButton === -1 ? 'active' : ''}
          handleClick={() => {
            handleFloristOrders(-1);
          }}
        />

        {floristsList &&
          floristsList?.map((florist) => {
            return (
              <CustomButton
                name={florist?.name}
                type="florists"
                cssClasses={florist?.id === activeButton ? 'active' : ''}
                handleClick={() => {
                  handleFloristOrders(florist?.id);
                }}
                key={florist?.id}
              />
            );
          })}
      </div>
    </>
  );
};

export default Florists;
