import { convertObjectIntoQueryParams } from 'constants/Helpers';
//actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
//api
import OrdersListService from 'headflorist/services/OrdersListService';
//action types
import { SET_IS_SEARCHING_FALSE, SET_IS_SEARCHING_TRUE } from './OrdersActionTypes';
//selectors
import { getOrdersIsSearching } from './OrdersListSelectors';

export const setIsSearchingTrue = () => ({ type: SET_IS_SEARCHING_TRUE });

export const setIsSearchingFalse = () => ({ type: SET_IS_SEARCHING_FALSE });

export const setOrdersListData = (params, slotId, navigate) => async (dispatch, getState) => {
  const state = getState(),
    isSearching = getOrdersIsSearching({ state });
  dispatch(setLoaderOpenTrue());
  try {
    const res = await OrdersListService.getordersListData(params, slotId);
    dispatch({
      type: 'SET_ORDERS_LIST_DATA',
      payload: {
        ordersList: res.data.data,
        pageCount: res.data.pageCount,
        isFlorist: params.isFlorist ? params.isFlorist : params.floristId === -1 ? false : true,
        searchKey: params.searchKey,
      },
    });
    navigate({
      pathname: '/orders',
      search: convertObjectIntoQueryParams(params),
    });
    dispatch(setLoaderOpenFalse());
    if (isSearching) {
      dispatch(setIsSearchingFalse());
    }
  } catch (err) {
    dispatch(setLoaderOpenFalse());
  }
};

export const setGroupBySkuData = (params, page) => async (dispatch) => {
  if (page) {
    params.page = page;
  }

  dispatch(setLoaderOpenTrue());
  try {
    const res = await OrdersListService.getGroupedBySku(params, params?.slotId);
    if (page) {
      dispatch({
        type: 'SET_GROUP_BY_SKU_DATA',
        payload: res.data,
      });
    } else {
      dispatch({
        type: 'SET_GROUP_BY_SKU_APPENDED_DATA',
        payload: res.data,
      });
    }
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    dispatch(setLoaderOpenFalse());
  }
};

export const setGroupByZoneData = (params, page) => async (dispatch) => {
  if (page) {
    params.page = page;
  }
  dispatch(setLoaderOpenTrue());
  try {
    const res = await OrdersListService.getGroupedByZone(params, params?.slotId);
    if (page) {
      dispatch({
        type: 'SET_GROUP_BY_ZONE_APPENDED_DATA',
        payload: res.data,
      });
    } else {
      dispatch({
        type: 'SET_GROUP_BY_ZONE_DATA',
        payload: res.data,
      });
    }
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    dispatch(setLoaderOpenFalse());
  }
};

export const getFloristsData = (params) => async (dispatch) => {
  try {
    const res = await OrdersListService.getFloristsList(params);
    dispatch({
      type: 'SET_FLORISTS_DATA',
      payload: res?.data,
    });
  } catch (err) {
    // @TODO add datadog
  }
};
