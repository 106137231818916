import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class ProductionService {
  static getUnassignedBatches(params) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getUnassignedBatches),
      params,
    });
  }
}
export default ProductionService;
