/* eslint-disable indent */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// Components
import CustomSelect from 'headflorist/components/common/CustomSelect';
// Helpers
import { sortFilterData, convertQueryParamsIntoObject } from 'constants/Helpers';
// Selectors
import { getDeliveryTimeSlot } from 'headflorist/store/orders/OrdersListSelectors';

const SortGroupSection = ({ fetchData, setInitialParams, baseURL }) => {
  const deliveryTimeSlot = useSelector((state) => getDeliveryTimeSlot({ state }));
  const location = useLocation();
  const navigate = useNavigate();
  const queryObj = location.search ? convertQueryParamsIntoObject(location.search) : {};

  const handleGroupBy = (e) => {
    const clonedParams = { ...queryObj, FloristOrdersGroupBy: e.itemData.value, page: 1 };
    setInitialParams(clonedParams);
    const queryString =
      '?' +
      Object.keys(clonedParams)
        .map((key) => `${key}=${clonedParams[key]}`)
        .join('&');
    navigate({
      pathname: baseURL,
      search: queryString,
    });
  };

  const handleSortBy = (e) => {
    const clonedParams = { ...queryObj, sortBy: e.itemData.value, page: 1 };
    fetchData(clonedParams, 1, true);
  };

  return (
    <div className="sort-group-wrapper">
      <div className="slot-date-wrapper">
        <h5 className="header-selected-date">{moment(queryObj.deliveryDate).format('dddd ll')}</h5>
        <h5 className="header-selected-slot">{deliveryTimeSlot}</h5>
      </div>
      <div className="selects-wrapper">
        <div className="header-groupBy-wrapper">
          <CustomSelect
            id="groupBy"
            label="Group by:"
            listData={[
              { value: '0', text: 'Product SKU' },
              { value: '1', text: 'Delivery Zone' },
              { value: '-1', text: 'Default' },
            ]}
            textPropName="text"
            valuePropName="value"
            handleChange={handleGroupBy}
            selectedValue={queryObj.FloristOrdersGroupBy}
            allowFiltering={false}
          />
        </div>
        <div className="header-sortBy-wrapper">
          <CustomSelect
            id="sortBy"
            label="Sort by:"
            listData={sortFilterData}
            textPropName="text"
            valuePropName="value"
            handleChange={(e) => handleSortBy(e)}
            selectedValue={queryObj.sortBy}
            allowFiltering={false}
          />
        </div>
      </div>
    </div>
  );
};

export default SortGroupSection;
