import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// Helpers
import {
  isoStartFormat,
  isoEndFormat,
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
} from 'constants/Helpers';
// Components
import JobCard from 'components/production/JobCard';
import ConfirmationModal from 'components/common/ConfirmationModal';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Lightbox from 'react-image-lightbox';
import DateRange from 'components/common/DateRange';
import DownArrow from 'assets/images/svg/DownArrow';
// Actions
import { getUnassignedBatchesData } from 'store/production/ProductionActions';
// Selectors
import { getIsLoaderOpen } from 'store/app/AppSelectors';
import { getProductionData } from 'store/production/ProductionSelectors';

const ProductionHomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state }));
  const data = useSelector((state) => getProductionData({ state }));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
  const [clickedImg, setClickedImg] = useState('');
  const [assignedNbr, setAssignedNbr] = useState(0);
  const [dateRangeVal, setDateRangeVal] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  const [showDateRange, setShowDateRange] = useState(false);

  const handleProceed = () => {
    // Send data to assign API
    // Navigate to start page on success
    /* navigate({
      pathname: ``,
      search: ,
    }); */
  };

  const handleDateChange = (item) => {
    setDateRangeVal(item.selection);
  };

  const fetchData = (queryObj) => {
    if (queryObj) {
      dispatch(getUnassignedBatchesData(queryObj));
    } else {
      queryObj = {
        productionStartDate: isoStartFormat(dateRangeVal.startDate),
        productionEndDate: isoEndFormat(dateRangeVal.endDate),
      };

      navigate({
        pathname: '/production',
        search: convertObjectIntoQueryParams(queryObj),
      });

      dispatch(getUnassignedBatchesData(queryObj));
    }
  };

  useEffect(() => {
    if (location.search) {
      const queryObj = convertQueryParamsIntoObject(location.search);
      if ('productionStartDate' in queryObj && 'productionEndDate' in queryObj) {
        setDateRangeVal({
          startDate: new Date(queryObj?.productionStartDate),
          endDate: new Date(queryObj?.productionEndDate),
          key: 'selection',
        });
      }
      fetchData(queryObj);
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLightBoxOpen && (
        <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBoxOpen(false)} />
      )}
      {isLoaderOpen && <LoadingOverlay />}
      {isModalOpen && (
        <ConfirmationModal
          handleCancelClick={() => setIsModalOpen(false)}
          handleProceedClick={handleProceed}
          message={`Are you sure you want to assign all the tasks <b>"${assignedNbr} products"</b>?`}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
        />
      )}
      <div className="production-header">
        <div className="production-date-container">
          <div
            className="home-deliverydate-wrapper production-daterange-wrapper card"
            onClick={(e) => {
              if (e?.target?.id && e?.target?.id === 'production-daterange-apply') {
                setShowDateRange(false);
              } else {
                setShowDateRange(true);
              }
            }}
          >
            <p>Job date</p>
            <div className="delivery-arrow-wrapper">
              <DownArrow customCss={showDateRange ? 'upside-down' : ''} />
            </div>
            <DateRange
              id="production-daterange"
              label=""
              value={dateRangeVal}
              showDateRange={showDateRange}
              setShowDateRange={setShowDateRange}
              handleChange={handleDateChange}
              fetchData={fetchData}
            />
          </div>
        </div>
        <div className="production-tab-labels">
          <ul>
            <li>Photo</li>
            <li>Product name</li>
            <li>Job ID</li>
            <li>Total Task</li>
            <li>Tasks unassigned</li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
      {data.length > 0 ? (
        <div className="card-list-wrapper job-container">
          {data?.map((job) =>
            job?.unassignedJobsDTO?.map((product) => {
              return product?.unassignedBatches?.map((batch) => (
                <JobCard
                  imgSrc={product?.productImage}
                  productName={product?.productName}
                  jobID={product.id}
                  totalTasks={batch?.actualSize}
                  unassignedTasks={batch?.totalUnassignedTasks}
                  setShowConfirmation={setIsModalOpen}
                  setAssignedNbr={setAssignedNbr}
                  setClickedImg={setClickedImg}
                  setIsLightBoxOpen={setIsLightBoxOpen}
                  key={batch?.batchId}
                />
              ));
            })
          )}
        </div>
      ) : (
        <p className="no-data">No jobs to show</p>
      )}
    </>
  );
};

export default ProductionHomePage;
