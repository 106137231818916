import React from 'react';
// Components
import CustomButton from 'headflorist/components/common/CustomButton';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const QualityCheckConfirmModal = ({
  message,
  handleCancelClick,
  handleProceedClick,
  isOpen,
  setIsOpen,
  proceedBtnName = 'Proceed',
  cancelBtnName = 'Cancel',
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen((prev) => !prev)}
      className="confirmation-modal-container"
      centered={true}
    >
      <ModalBody className="confirmation-modal">
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></p>
      </ModalBody>
      <ModalFooter className="confirmation-btns">
        <CustomButton
          id="minus-btn"
          name={cancelBtnName}
          handleClick={handleCancelClick}
          type="cancel"
        />
        <CustomButton
          id="minus-btn"
          name={proceedBtnName}
          handleClick={handleProceedClick}
          type="proceed"
        />
      </ModalFooter>
    </Modal>
  );
};

export default QualityCheckConfirmModal;
