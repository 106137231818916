import { apiFloristGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class NotificationsService {
  static getNotificationsList(...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getNotificationsList, ...extraParams),
    });
  }

  static getReadNotification(...extraParams) {
    return apiFloristGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getReadNotification, ...extraParams),
    });
  }

  static getProceedAck(...extraParams) {
    return apiFloristGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.getProceedAck, ...extraParams),
    });
  }

  static getRejectAck(...extraParams) {
    return apiFloristGatewayService({
      method: 'DELETE',
      url: setEndPoint(endPoints.getRejectAck, ...extraParams),
    });
  }
}
export default NotificationsService;
