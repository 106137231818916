import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import CustomButton from 'headflorist/components/common/CustomButton';
// Actions
import { setOrdersListData } from 'headflorist/store/orders/OrdersListActions';
// Selectors
import { getFloristsList } from 'headflorist/store/orders/OrdersListSelectors';

const Florists = ({ setCounter, initialParams, slotId, fetchFlorists }) => {
  const floristsList = useSelector((state) => getFloristsList({ state }));
  const [activeButton, setActiveButton] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFloristOrders = (floristId) => {
    setActiveButton(floristId);
    dispatch({
      type: 'SET_ORDERS_LIST_DATA',
      payload: {
        ordersList: [],
        pageCount: 1,
        isFlorist: true,
      },
    });
    setCounter(1);
    dispatch(
      setOrdersListData({ ...initialParams, page: 1, floristId: floristId }, slotId, navigate)
    );
  };

  useEffect(() => {
    fetchFlorists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="florists-container">
        <CustomButton
          name={'All Orders'}
          type="florists"
          cssClasses={activeButton === -1 ? 'active' : ''}
          handleClick={() => {
            handleFloristOrders(-1);
          }}
        />

        {floristsList &&
          floristsList?.map((florist) => {
            return (
              <div className="florist-filter-wrapper" key={florist?.id}>
                <CustomButton
                  name={florist?.name}
                  type="florists"
                  cssClasses={florist?.id === activeButton ? 'active' : ''}
                  handleClick={() => {
                    handleFloristOrders(florist?.id);
                  }}
                  key={florist?.id}
                />
                <div className="florist-order-count">
                  <p>{florist?.orderCount}</p>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Florists;
