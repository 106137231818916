import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import ChangeStatusBtn from './ChangeStatusBtn';
import Lightbox from 'react-image-lightbox';
import StarIcon from 'assets/images/svg/StarIcon';
// Helpers
import { colleagueStatus } from 'constants/Helpers';
// Selectors
import { getIsLoaderOpen } from 'store/app/AppSelectors';

const OrdersList = ({ ordersListData, fetchMoreData, initialParams, onScreen }) => {
  const navigate = useNavigate();
  const [isLightBox, setIsLightBox] = useState(false);
  const [clickedImg, setClickedImg] = useState('');
  const isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state }));

  useEffect(() => {
    if (onScreen && !isLoaderOpen) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return (
    <>
      <div className="orderWrapper">
        {ordersListData?.length > 0 ? (
          <>
            {ordersListData?.map((item, i) => (
              <div className="card orderCard" key={i}>
                <div className="card-header orderHeader">
                  <div className="assign-florist">
                    <h6 className="mb-0 product-name">
                      {' '}
                      Order {item?.orderPriority === 1 && <span className="order-priority">!</span>}
                    </h6>
                    <h6
                      onClick={() => navigate(`/orderdetails/${item?.orderId}`)}
                      className="order-id"
                    >
                      {item?.orderId}
                    </h6>
                    {item?.nwDelivery ? <div className="nw-order">NW</div> : null}
                    {item?.productionLine === 1 && <StarIcon cssClass="star-icon" />}
                    {item?.membershipType === 1 && <div className="vip-order">VIP</div>}
                  </div>
                  <div className="assign-florist">
                    {
                      <h6>
                        Items <span className="item">({item?.cartList?.length})</span>
                      </h6>
                    }
                  </div>
                  <div className="assign-florist">
                    <h6 className="mb-0">Zone</h6>
                    <h6>{item?.zone ?? ''}</h6>
                  </div>
                  <div className="assign-florist">
                    <h6 className="mb-0 florist-name">
                      {item?.florist ? item?.florist?.name : ''}
                    </h6>
                    <h6 className="prep-status">
                      {item?.preparationStatus ? colleagueStatus[item?.preparationStatus] : ''}
                    </h6>
                  </div>
                  <div className="statusBtn">
                    <ChangeStatusBtn
                      item={item}
                      fetchMoreData={fetchMoreData}
                      initialParams={initialParams}
                      details={false}
                    />
                  </div>
                </div>
                <div
                  className="card-body"
                  onClick={() => navigate(`/orderdetails/${item?.orderId}`)}
                >
                  <div className="card-body-content">
                    {item?.cartList?.map((el, i) => (
                      <div key={i} className="cart-list">
                        <img
                          alt={'product'}
                          src={el?.image}
                          onClick={(e) => {
                            e.stopPropagation();
                            setClickedImg(el?.image);
                            setIsLightBox(true);
                          }}
                        />
                        <h6 className={`${el?.qty > 1 && 'red'}`}>
                          {el?.qty} x - {el?.sku}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
            {isLightBox && (
              <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
            )}
          </>
        ) : (
          <h4 className="no-orders-title">No orders</h4>
        )}
      </div>
    </>
  );
};

export default OrdersList;
