import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
// Helpers
import { colleagueStatus, convertQueryParamsIntoObject } from 'constants/Helpers';
// Components
import ChangeStatusBtn from 'headflorist/components/orders/ChangeStatusBtn';
import StarIcon from 'headflorist/assets/images/svg/StarIcon';
// Selectors
import {
  getGroupByZoneCurrentPage,
  getGroupByZonePageCount,
} from 'headflorist/store/orders/OrdersListSelectors';
// Actions
import { setGroupByZoneData } from 'headflorist/store/orders/OrdersListActions';

const ByZone = ({
  navigate,
  groupByZoneData,
  onStatusChanged,
  onScreen,
  isLoaderOpen,
  fetchMoreData,
  isMyTask,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPage = useSelector((state) => getGroupByZoneCurrentPage({ state }));
  const pageCount = useSelector((state) => getGroupByZonePageCount({ state }));

  useEffect(() => {
    if (onScreen && !isLoaderOpen) {
      if (currentPage < pageCount) {
        const queryObj = convertQueryParamsIntoObject(location.search);
        dispatch(setGroupByZoneData(queryObj, currentPage + 1));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return (
    <div className="orderWrapper">
      {groupByZoneData?.length > 0 ? (
        <>
          {groupByZoneData?.map((item, i) => (
            <div className="card orderCard" key={i}>
              <div className="card-header orderHeader">
                <div className="assign-florist">
                  <h6 className="mb-0 product-name">Zone {item?.deliveryZone}</h6>
                </div>
                <div className="assign-florist">
                  <h6 className="mb-0 product-name">No. of orders {item?.ordersCount}</h6>
                </div>
              </div>
              <div className="card-body">
                <div className="card-body-content">
                  {item?.ordersDataDto?.map((el, n) => (
                    <div key={n} className="order-list">
                      <div className="assign-florist">
                        <h6 className="mb-0 order">
                          Order{' '}
                          {el?.orderPriority === 1 ? <span className="order-priority">!</span> : ''}
                        </h6>
                        <h6
                          onClick={() => navigate(`/orderdetails/${el?.id}`)}
                          className="order-id"
                        >
                          {el?.id}
                        </h6>
                        {el?.productionLine === 1 && <StarIcon cssClass="star-icon" />}
                        {el?.membershipType === 1 && <div className="vip-order">VIP</div>}
                      </div>
                      <div className="assign-florist">
                        <h6 className="mb-0 florist-name">
                          {el?.floristDto?.name && el?.floristDto?.name}
                        </h6>
                        <h6 className="prep-status">
                          {el?.preparationStatus && colleagueStatus[el?.preparationStatus]}
                        </h6>
                      </div>
                      <div className="statusBtn">
                        <ChangeStatusBtn
                          item={el}
                          onSuccess={onStatusChanged}
                          index={i}
                          subIndex={n}
                          type="status"
                          fetchMoreData={fetchMoreData}
                          // showAllCTA={isMyTask}
                          showAllCTA={true}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <h4 className="no-orders-title">No orders</h4>
      )}
    </div>
  );
};

export default ByZone;
