import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CustomSelect from 'components/common/CustomSelect';
import CustomInput from 'components/common/CustomInput';
import CustomButton from 'components/common/CustomButton';
import microsoftSVG from 'assets/images/svg/microsoft.svg';
import AuthService from 'services/AuthService';
// actions
import { setIsLoggedInTrue } from 'store/app/AppActions';
import { addToLocalStorage } from 'helpers/LocalStorage';
import { toast } from 'react-toastify';

const LoginPage = () => {
  const [phone, setPhone] = useState(''),
    [password, setPassword] = useState(''),
    [countryId, setCountryId] = useState(-1),
    [disabled, setDisabled] = useState(false),
    [countriesList, setCountriesList] = useState([]),
    navigate = useNavigate(),
    dispatch = useDispatch();

  const handleLogin = async () => {
    try {
      setDisabled(true);
      const { data } = await AuthService.logInFlorist({ phone, password, countryId: +countryId });
      if (!data.hasError) {
        validateSession(data.data);
      }
      setDisabled(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong!');
      setDisabled(false);
    }
  };

  const validateSession = async (sessionId) => {
    try {
      let sessionData = await AuthService.validateSession(sessionId);
      addToLocalStorage('token', sessionData.data?.token);
      addToLocalStorage('opsId', sessionData.data?.operationCenter?.id);
      addToLocalStorage('floristId', sessionData.data?.user?.id);
      dispatch(setIsLoggedInTrue());
      navigate('/');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong!');
    }
  };

  const handleMicrosoftLogin = () => {
    window.location.href = `${process.env.REACT_APP_MICROSOFT_LOGIN}`;
  };

  const getCountryList = async () => {
    try {
      const res = await AuthService.getCountryCodes();
      res.data?.forEach((c) => (c.Code = '+' + c.Code));
      setCountriesList(res.data);
    } catch (err) {
      // error in getting country list
    }
  };

  const countryTemplate = (rowData) => (
    <div id={rowData.Id}>
      <span id={rowData.Id}>
        {rowData.Code}&nbsp;{rowData.Name}
      </span>
    </div>
  );

  // const handleOpen = (e) => {
  //   e.popup.element.querySelector('[aria-owns="countryLogin_options"]').name = 'search';
  // };

  useEffect(() => {
    getCountryList();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('sessionId');
    if (sessionId) {
      validateSession(sessionId);
    }
    const isFailed = urlParams.get('status');
    if (isFailed === 'failed') {
      toast.error('Microsoft login has failed');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-wrapper">
      <h4 className="login-title">Login</h4>
      <form className="login-form" onSubmit={(e) => e.preventDefault()} autoComplete="off">
        <div>
          <CustomSelect
            id="countryLogin"
            placeholder="Select your country"
            listData={countriesList}
            textPropName="Code"
            valuePropName="Id"
            handleChange={(e) => setCountryId(+e.itemData.Id)}
            selectedValue={countryId}
            template={countryTemplate}
            // delayUpdate
            // onOpen={handleOpen}
            allowFiltering={false}
          />
        </div>
        <CustomInput
          id="phoneLogin"
          value={phone}
          handleChange={(e) => setPhone(e.target.value)}
          type="number"
          placeholder="Phone number"
        />
        <CustomInput
          id="passwordLogin"
          value={password}
          handleChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="Password"
        />
        <CustomButton
          id="regularLogin"
          name="login"
          handleClick={handleLogin}
          disabled={!phone || !password || countryId === -1 || disabled}
          type={'login'}
        />
        <p className="or-text">OR</p>
        <CustomButton
          id="msLogin"
          name="Log in With Microsoft"
          handleClick={handleMicrosoftLogin}
          type={'login-microsoft'}
          icon={microsoftSVG}
          iconCss="ms-icon"
        />
      </form>
    </div>
  );
};

export default LoginPage;
