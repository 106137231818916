import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from './CustomButton';
import NotificationIcon from 'assets/images/svg/NotificationIcon';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { convertToDateAndTime } from 'constants/Helpers';
//action
import { setReadNotification, setToggleNotificationAlert } from 'store/app/AppActions';
//selectors
import {
  getNotificationsList,
  getNotificationsLength,
  getIsNotificationsAlertOpen,
} from 'store/app/AppSelectors';
import { toast } from 'react-toastify';

const NotificationButton = () => {
  const notificationsList = useSelector((state) => getNotificationsList({ state })),
    notificationsLength = useSelector((state) => getNotificationsLength({ state })),
    showAlert = useSelector((state) => getIsNotificationsAlertOpen({ state })),
    [showPopover, setShowPopover] = useState(false),
    dispatch = useDispatch();

  const handleReadNotification = (item) => {
    if (item.read === false) {
      dispatch(setReadNotification(item.id));
    }
  };

  useEffect(() => {
    if (notificationsList.length > 0 && notificationsList.some((el) => el.read === false)) {
      if (showAlert) {
        toast.error(notificationsList.find((el) => el.read === false).content);
        setTimeout(() => {
          dispatch(setToggleNotificationAlert());
        }, 1500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsList, showAlert]);

  return (
    <div className="notification-btn-wrapper">
      {showPopover && (
        <Modal
          className="notification-modal"
          isOpen={showPopover}
          toggle={() => setShowPopover((prev) => !prev)}
        >
          <ModalHeader close={<></>} toggle={() => setShowPopover((prev) => !prev)}>
            Notifications
          </ModalHeader>
          {notificationsList.length > 0 && (
            <ModalBody>
              {notificationsList.map((item, i) => (
                <div
                  onClick={() => handleReadNotification(item)}
                  key={i}
                  className="notification-text"
                  button
                >
                  {item.content} {item.createdAt ? convertToDateAndTime(item.createdAt) : ''}
                  <img
                    alt=""
                    src="https://cdn.floward.com/web/Files/attachment/icons8-in_transit-15-637497660015920932.png"
                    className={`notification-img ${item.read ? '' : 'hide'}`}
                  />
                </div>
              ))}
            </ModalBody>
          )}
        </Modal>
      )}
      <CustomButton
        id="header-notifications"
        handleClick={() => setShowPopover((prev) => !prev)}
        type="header-icon"
        fontAwsome={<NotificationIcon />}
      />
      {notificationsList.length >= 0 && (
        <div onClick={() => setShowPopover((prev) => !prev)} className="notification-badge">
          <span>{notificationsLength}</span>
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
