import React from 'react';

const DownArrow = ({ customCss }) => {
  return (
    <svg
      className={customCss}
      width="11"
      height="6"
      viewBox="0 0 11 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.75 5.84375C4.90625 6 5.125 6 5.28125 5.84375L9.90625 1.28125C10.0625 1.125 10.0625 0.875 9.90625 0.75L9.28125 0.125C9.125 -0.0312501 8.90625 -0.0312501 8.75 0.125L5 3.8125L1.28125 0.124999C1.125 -0.0312508 0.875001 -0.0312508 0.750001 0.124999L0.125 0.749999C-0.0312496 0.874999 -0.0312496 1.125 0.125 1.28125L4.75 5.84375Z"
        fill="#323232"
      />
    </svg>
  );
};

export default DownArrow;
