import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CustomButton from 'headflorist/components/common/CustomButton';
import UploadQcImageModal from 'headflorist/components/qualityCheck/UploadQcImageModal';
import Lightbox from 'react-image-lightbox';
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
import { toast } from 'react-toastify';
// Icons
import CameraIcon from 'headflorist/assets/images/svg/CameraIcon';
import DeleteIcon from 'headflorist/assets/images/svg/DeleteIcon';
import ReloadIcon from 'assets/images/svg/ReloadIcon';
// API
import OrdersListService from 'headflorist/services/OrdersListService';

const HFQualityCheckPage = () => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isModalOpen, setIsOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [isLightBox, setIsLightBox] = useState(false);
  const [clickedImgIndex, setClickedImgIndex] = useState(0);
  const [failedImg, setFailedImg] = useState('');
  const params = useParams();
  const navigate = useNavigate();

  const handleOpenCameraModal = () => {
    if (data?.length < 4) {
      setIsOpenModal(true);
    }
  };

  const handleRemoveImg = (e, imgEl) => {
    e.stopPropagation();
    const filteredImgs = data?.filter((el) => el?.newImg !== imgEl?.newImg);
    setData(filteredImgs);
  };

  const handleUploadImages = async () => {
    setIsDisable(true);
    setIsLoaderOpen(true);
    const dataBody = {
      status: 0,
      qcImageList: data?.map((el) => el?.imgName),
    };
    try {
      await OrdersListService.changeOrderPreparationStatus(dataBody, params?.orderId);
      navigate(`/orderdetails/${params?.orderId}`);
    } catch (err) {
      toast.err(err?.response?.data?.message ?? 'Failed to proceed');
    } finally {
      setIsDisable(false);
      setIsLoaderOpen(false);
    }
  };

  return (
    <div className="card quality-check-wrapper">
      {isModalOpen && (
        <UploadQcImageModal
          isModalOpen={isModalOpen}
          setIsOpenModal={setIsOpenModal}
          data={data}
          setData={setData}
          setIsLoaderOpen={setIsLoaderOpen}
          setFailedImg={setFailedImg}
        />
      )}
      <div className="card-body">
        {isLoaderOpen && <LoadingOverlay />}
        <div className="uploader-container">
          <h3 className="page-header">Complete Quality Check</h3>
          <p className="page-desc">
            {`Upload individual product images and the entire order image for Order ID ${params?.orderId}`}
          </p>
          <p className="upload-instr">Upload flower-related product image</p>
          <div className="uploaded-images-wrapper">
            {data?.map((el, i) => (
              <div
                key={i}
                className="camera-small-preview"
                style={{ backgroundImage: `url(${el?.newImg})` }}
                onClick={() => {
                  setIsLightBox(true);
                  setClickedImgIndex(i);
                }}
              >
                <div className="delete-icon" onClick={(e) => handleRemoveImg(e, el)}>
                  <DeleteIcon fill="#FE3D5E" />
                </div>
              </div>
            ))}
            {failedImg && (
              <div className="reload-img-container">
                <div
                  className="camera-dashed-container"
                  style={{ backgroundImage: `url(${failedImg})` }}
                  onClick={handleOpenCameraModal}
                >
                  <div className="overlay"></div>
                  <div className="reload-icon">
                    <ReloadIcon />
                  </div>
                </div>
              </div>
            )}
            <div className="camera-functionality-container">
              <div
                className={`camera-dashed-container ${
                  data?.length > 3 ? 'disable-open-camera' : ''
                }`}
                onClick={handleOpenCameraModal}
              >
                <CameraIcon />
                <span className="take-photo">Take photo</span>
              </div>
            </div>
          </div>
          {isLightBox && (
            <Lightbox
              mainSrc={data[clickedImgIndex]?.newImg}
              nextSrc={data[(clickedImgIndex + 1) % data?.length]?.newImg}
              prevSrc={data[(clickedImgIndex + data?.length - 1) % data?.length]?.newImg}
              onCloseRequest={() => setIsLightBox(false)}
              onMovePrevRequest={() =>
                setClickedImgIndex((clickedImgIndex + data.length - 1) % data?.length)
              }
              onMoveNextRequest={() => setClickedImgIndex((clickedImgIndex + 1) % data?.length)}
            />
          )}
          <span>Upload up to 4 images | Max size: 5 MB per image</span>
        </div>

        <CustomButton
          id="proceed-reason"
          handleClick={handleUploadImages}
          type="success"
          name="Done"
          disabled={data?.length === 0 || isDisable}
        />
      </div>
    </div>
  );
};

export default HFQualityCheckPage;
