import { setLoaderOpenTrue, setLoaderOpenFalse } from '../app/AppActions';
//api
import OrderDetailsService from 'headflorist/services/OrderDetailsService';

const setOrderDetails = (orderId) => async (dispatch) => {
  try {
    const res = await OrderDetailsService.getOrderDetails(orderId);
    dispatch({
      type: 'SET_ORDER_DETAILS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setCartDetails = (orderId) => async (dispatch) => {
  try {
    const res = await OrderDetailsService.getCartDetails(orderId);
    dispatch({
      type: 'SET_CART_DETAILS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setDeviationReasonsData = () => async (dispatch) => {
  try {
    const res = await OrderDetailsService.getDeviationReasonsData();
    dispatch({
      type: 'SET_DEVIATION_REASONS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
  }
};

//QC images
export const getQcImages = (orderId) => async (dispatch) => {
  try {
    const res = await OrderDetailsService.getQcImages(orderId);
    dispatch({
      type: 'SET_QC_IMAGES',
      payload: res?.data?.productImageFullUrl || [],
    });
  } catch (err) {
    // errorLogger('ViewOrderActions:getQcImages', err);
  }
};

export const setOrderDetailsAllData = (orderId) => async (dispatch) => {
  let promisesArray = [];
  dispatch(setLoaderOpenTrue());
  try {
    promisesArray.push(
      dispatch(setOrderDetails(orderId)),
      dispatch(setCartDetails(orderId)),
      dispatch(setDeviationReasonsData()),
      dispatch(getQcImages(orderId))
    );
    await Promise.all(promisesArray);
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    dispatch(setLoaderOpenFalse());
  }
};
