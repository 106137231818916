import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import Lightbox from 'react-image-lightbox';
import { toast } from 'react-toastify';
import CustomButton from 'headflorist/components/common/CustomButton';
import UploadImageModal from './UploadImageModal';
import ReasonsModal from './ReasonsModal';
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
// Icons
import CameraIcon from 'headflorist/assets/images/svg/CameraIcon';
import PlusIcon from 'headflorist/assets/images/svg/PlusIcon';
import DeleteIcon from 'headflorist/assets/images/svg/DeleteIcon';
// Selectors
import { getPermissionsData } from 'headflorist/store/app/AppSelectors';
import { getFirebasePermissions } from 'store/app/AppSelectors';
// Helpers
import { hasPermission } from 'constants/Helpers';
// Localstorage
import { getFromLocalStorage } from 'headflorist/helpers/LocalStorage';

const InventoryTable = ({
  inventoryData,
  orderMapId,
  preparationStatus,
  hasSubmittedBefore,
  hasDeviation,
  setHasSubmittedBefore,
  setHasDeviation,
  cartQty,
  fetchCartDetails,
  cartId,
}) => {
  const [isLightBox, setIsLightBox] = useState(false),
    [picInd, setPicInd] = useState(0),
    [clickedImg, setClickedImg] = useState(''),
    [isModalOpen, setIsOpenModal] = useState(false),
    [selectedItem, setSelectedItem] = useState({}),
    [data, setData] = useState([]),
    [apiPayload, setApiPayload] = useState([]),
    [isReasonModalOpen, setIsReasonModalOpen] = useState(false),
    [orangeBorder, setOrangeBorder] = useState([]),
    [isLoading, setIsLoading] = useState(true),
    [isHoF, setIsHoF] = useState(false),
    permissions = useSelector((state) => getPermissionsData({ state })),
    firebasePermissions = useSelector((state) => getFirebasePermissions({ state })),
    opsID = getFromLocalStorage('opsId'),
    editDeviationEnabled = firebasePermissions?.enable_edit_deviation?.filter(
      (ops) => ops.id === parseInt(opsID)
    )?.[0]?.enable;

  const checkDisable = (newdata = data) => {
    let isDiasbled = true;
    newdata?.forEach((d) => {
      if (d?.usedQty !== d?.qty || d?.damagedQty > 0) {
        isDiasbled = false;
      }
    });
    let newDeviation = hasDeviation?.map((d) => {
      if (d?.productId === newdata[0]?.productId && d?.cartId === cartId) {
        d.hasDeviation = !isDiasbled;
      }
      return d;
    });
    setHasDeviation(newDeviation);
  };

  const checkEditDisable = (newdata = data) => {
    let isDiasbled = true;
    newdata?.forEach((d, ind) => {
      if (
        d?.usedQty !== apiPayload[ind]?.usedQty ||
        d?.damagedQty !== apiPayload[ind]?.damagedQty
      ) {
        isDiasbled = false;
      }
    });
    let newDeviation = hasDeviation?.map((d) => {
      if (d?.productId === newdata[0]?.productId && d?.cartId === cartId) {
        d.hasEditDeviation = !isDiasbled;
      }
      return d;
    });
    setHasDeviation(newDeviation);
  };

  const handleUsedChange = (e, item) => {
    let isOrange = false;
    if (Number.isNaN(e.target.valueAsNumber)) {
      e.target.value = 0;
    } else {
      if (parseInt(e.target.value) - item?.qty + item?.damagedQty > item?.availableQty) {
        e.target.value =
          item?.availableQty - item?.damagedQty > 0
            ? item?.availableQty - item?.damagedQty + item?.qty
            : item?.qty;
        toast.error(
          'Cannot add quantity more than the available quantity. Please speak to your supervisor.'
        );
      }
    }
    const newData = data?.map((p, index) => {
      if (p?.id === item?.id) {
        p.usedQty = parseInt(e.target.value);
        if (parseInt(e.target.value) !== p?.qty) {
          isOrange = true;
        }
        orangeBorder[index].used = isOrange;
      }
      return p;
    });
    setData(newData);
    checkDisable(newData);
    checkEditDisable(newData);
    setOrangeBorder(orangeBorder);
  };

  const handleDamagedChange = (e, item) => {
    if (Number.isNaN(e.target.valueAsNumber)) {
      e.target.value = 0;
    } else {
      if (parseInt(e.target.value) - item?.qty + item?.usedQty > item?.availableQty) {
        e.target.value =
          item?.availableQty - item?.usedQty + item?.qty > 0
            ? item?.availableQty - item?.usedQty + item?.qty
            : 0;
        toast.error(
          'Cannot add quantity more than the available quantity. Please speak to your supervisor.'
        );
      }
    }
    const newData = data?.map((p, index) => {
      if (p?.id === item?.id) {
        p.damagedQty = parseInt(e.target.value);
        orangeBorder[index].damaged = parseInt(e.target.value) > 0;
      }
      return p;
    });
    setData(newData);
    checkDisable(newData);
    checkEditDisable(newData);
  };

  const handleOpenCamera = (item) => {
    setSelectedItem(item);
    setIsOpenModal(true);
  };

  const deleteImages = (item) => {
    const newData = data?.map((p) => {
      if (p?.id === item?.id) {
        p.damagedImages = [];
      }
      return p;
    });
    setData(newData);
  };

  const handleSubmitDeviation = () => {
    let showError = false;
    data?.forEach((d) => {
      if (d?.damagedQty > 0 && (d?.damagedImages === null || d?.damagedImages?.length <= 0)) {
        showError = true;
      }
    });
    if (showError) {
      toast.error('Take photos of the damaged Items');
    } else {
      setIsReasonModalOpen(true);
    }
  };

  const isBtnDisabled = () => {
    const tableDeviation = hasDeviation?.filter(
      (dv) => dv?.productId === data[0]?.productId && dv?.cartId === cartId
    )?.[0]?.hasDeviation;
    return !tableDeviation || preparationStatus !== 2 || isSubmitted();
  };

  const isEditDisabled = () => {
    const tableDeviation = hasDeviation?.filter(
      (dv) => dv?.productId === data[0]?.productId && dv?.cartId === cartId
    )?.[0]?.hasEditDeviation;
    return !tableDeviation || isHofSubmitted();
  };

  const isSubmitted = () => {
    return hasSubmittedBefore?.filter(
      (sb) => sb?.productId === data[0]?.productId && sb?.cartId === cartId
    )?.[0]?.hasSubmittedBefore;
  };

  const isHofSubmitted = () => {
    return data?.find((p) => p.floristId !== null);
  };

  const isReadOnly = () => {
    if (isHoF && preparationStatus === 0 && editDeviationEnabled) {
      return isHofSubmitted();
    } else {
      return isSubmitted() || preparationStatus !== 2;
    }
  };

  useEffect(() => {
    let tempSubmit = false,
      tempData = [],
      orangeArr = [];
    setIsLoading(true);
    if (inventoryData) {
      // tempData = inventoryData;
      inventoryData?.forEach((r) => {
        if (r?.usedQty > 0 || r?.requiredQty > 0) {
          tempSubmit = true;
        }
      });
      tempData = inventoryData?.map((r) => {
        const itm = { ...r };
        if (!tempSubmit) {
          itm.usedQty = itm.qty * cartQty;
          itm.qty = itm.qty * cartQty;
          itm.requiredQty = itm.qty;
        } else {
          if (itm?.requiredQty === 0) {
            itm.requiredQty = itm.qty * cartQty;
            itm.usedQty = itm.qty * cartQty;
          }
        }
        orangeArr.push({
          id: itm?.id,
          used: itm?.usedQty !== (tempSubmit ? itm?.requiredQty : itm?.qty),
          damaged: itm?.damagedQty > 0,
        });
        return itm;
      });
      let newsubmitted = hasSubmittedBefore?.map((d) => {
        if (d?.productId === tempData[0]?.productId && d?.cartId === cartId) {
          d.hasSubmittedBefore = tempSubmit;
        }
        return d;
      });
      setOrangeBorder(orangeArr);
      setData(tempData);
      setApiPayload(cloneDeep(tempData));
      checkDisable(tempData);
      setHasSubmittedBefore(newsubmitted);
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryData]);

  useEffect(() => {
    setIsHoF(hasPermission(permissions, 'florist_orders', 'head_assign'));
  }, [permissions]);

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {isModalOpen && (
        <UploadImageModal
          isModalOpen={isModalOpen}
          setIsOpenModal={setIsOpenModal}
          selectedItem={selectedItem}
          payload={data}
          setPayload={setData}
        />
      )}
      {isReasonModalOpen && (
        <ReasonsModal
          isReasonModalOpen={isReasonModalOpen}
          setIsReasonModalOpen={setIsReasonModalOpen}
          payload={data}
          setPayload={setData}
          orderMapId={orderMapId}
          fetchCartDetails={fetchCartDetails}
          cartId={cartId}
          isEditDeviation={
            isHoF && preparationStatus === 0 && editDeviationEnabled && !isHofSubmitted()
          }
          inventoryData={inventoryData}
        />
      )}
      {!isLoading && (
        <div className="product-details-table">
          <div className="table-container">
            <table className="table">
              <tbody>
                {data &&
                  data?.map((el, n) => {
                    return (
                      <Fragment key={n}>
                        <tr>
                          <td>
                            <div
                              onClick={() => {
                                setClickedImg(el?.image);
                                setIsLightBox(true);
                              }}
                              style={{ backgroundImage: `url(${el?.image})` }}
                              className="inventoryTable-image"
                              alt="product"
                            ></div>
                          </td>
                          <td>{el?.name}</td>
                          <td>{el?.barcode}</td>
                          <td>{isSubmitted() ? el?.requiredQty : el?.qty}</td>
                          <td>
                            <input
                              className={`qty-input ${
                                orangeBorder[n]?.used ? 'orange-border' : ''
                              }`}
                              defaultValue={isSubmitted() ? el?.usedQty : el?.qty}
                              type="number"
                              min={0}
                              onChange={(e) => handleUsedChange(e, el)}
                              readOnly={isReadOnly()}
                            />
                          </td>
                          <td>
                            <input
                              className={`qty-input ${
                                orangeBorder[n]?.damaged ? 'orange-border' : ''
                              }`}
                              defaultValue={el?.damagedQty}
                              type="number"
                              min={0}
                              onChange={(e) => handleDamagedChange(e, el)}
                              readOnly={isReadOnly()}
                            />
                          </td>
                          <td>
                            <div className="camera-functionality-container">
                              {el?.damagedImages && el?.damagedImages.length > 0 ? (
                                <div
                                  className="camera-small-preview"
                                  style={{ backgroundImage: `url(${el?.damagedImages?.[0]})` }}
                                  onClick={() => {
                                    setClickedImg(el?.damagedImages);
                                    setIsLightBox(true);
                                  }}
                                ></div>
                              ) : (
                                <div
                                  className={`camera-dashed-container ${
                                    isReadOnly() ? 'disable-open-camera' : ''
                                  }`}
                                  onClick={isReadOnly() ? null : () => handleOpenCamera(el)}
                                >
                                  <CameraIcon />
                                </div>
                              )}
                              {isLightBox && (
                                <Lightbox onCloseRequest={() => setIsLightBox(false)} />
                              )}
                              <div className="camera-icons-container">
                                <div
                                  className={`camera-add-more ${
                                    el?.damagedImages?.length === 2 || isReadOnly()
                                      ? 'disable-add-more'
                                      : ''
                                  }`}
                                  onClick={() =>
                                    el?.damagedImages?.length === 2 || isReadOnly()
                                      ? null
                                      : handleOpenCamera(el)
                                  }
                                >
                                  <PlusIcon
                                    fill={
                                      el?.damagedImages?.length === 2 || isReadOnly()
                                        ? '#ffffff'
                                        : '#006782'
                                    }
                                  />
                                </div>
                                <div
                                  className={`camera-delete-all ${
                                    isReadOnly() ? 'disable-add-more' : ''
                                  }`}
                                  onClick={() => (isReadOnly() ? null : deleteImages(el))}
                                >
                                  <DeleteIcon fill={isReadOnly() ? '#ffffff' : '#FE3D5E'} />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        {isLightBox && (
                          <Lightbox
                            mainSrc={Array.isArray(clickedImg) ? clickedImg[picInd] : clickedImg}
                            nextSrc={
                              Array.isArray(clickedImg)
                                ? clickedImg[(picInd + 1) % 2] ?? clickedImg[0]
                                : clickedImg
                            }
                            prevSrc={
                              Array.isArray(clickedImg)
                                ? clickedImg[(picInd + 1) % 2] ?? clickedImg[0]
                                : clickedImg
                            }
                            onCloseRequest={() => setIsLightBox(false)}
                            onMovePrevRequest={() => setPicInd((picInd + 1) % 2)}
                            onMoveNextRequest={() => setPicInd((picInd + 1) % 2)}
                          />
                        )}
                      </Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <CustomButton
            id="submit-deviation"
            name="Submit"
            handleClick={() => handleSubmitDeviation()}
            disabled={
              isHoF && preparationStatus === 0 && editDeviationEnabled
                ? isEditDisabled()
                : isBtnDisabled()
            }
            type="primary"
            cssClasses="submit-deviation-btn"
          />
        </div>
      )}
    </>
  );
};

export default InventoryTable;
