import { updateObject } from '../Utility';
import { SET_IS_SEARCHING_FALSE, SET_IS_SEARCHING_TRUE } from './OrdersActionTypes';

const initialState = {
  deliveryDate: new Date(),
  ordersListData: [],
  pageCount: 1,
  deliveryTimeSlot: '',
  isSearching: false,
  groupBySku: {
    data: [],
    currentPage: 1,
    pageCount: 1,
  },
  groupByZone: {
    data: [],
    currentPage: 1,
    pageCount: 1,
  },
  floristsList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DELIVERY_DATE': {
      return updateObject(state, {
        deliveryDate: action.payload,
      });
    }

    case 'SET_DELIVERY_TIME_SLOT': {
      return updateObject(state, {
        deliveryTimeSlot: action.payload,
      });
    }

    case 'SET_ORDERS_LIST_DATA': {
      if (action.payload.isFlorist || action.payload.searchKey) {
        return updateObject(state, {
          ordersListData: action.payload.ordersList,
          pageCount: action.payload.pageCount,
        });
      } else {
        return updateObject(state, {
          ordersListData: [...state.ordersListData, ...action.payload.ordersList],
          pageCount: action.payload.pageCount,
        });
      }
    }
    case SET_IS_SEARCHING_TRUE: {
      return updateObject(state, { isSearching: true });
    }
    case SET_IS_SEARCHING_FALSE: {
      return updateObject(state, { isSearching: false });
    }

    case 'SET_GROUP_BY_SKU_DATA': {
      return updateObject(state, {
        groupBySku: action.payload,
      });
    }

    case 'SET_GROUP_BY_SKU_APPENDED_DATA': {
      return updateObject(state, {
        groupBySku: {
          ...state.groupBySku,
          data: [...state.groupBySku.data, ...action.payload.data],
          currentPage: action.payload.currentPage,
        },
      });
    }

    case 'SET_GROUP_BY_ZONE_DATA': {
      return updateObject(state, {
        groupByZone: action.payload,
      });
    }

    case 'SET_GROUP_BY_ZONE_APPENDED_DATA': {
      return updateObject(state, {
        groupByZone: {
          ...state.groupByZone,
          data: [...state.groupByZone.data, ...action.payload.data],
          currentPage: action.payload.currentPage,
        },
      });
    }

    case 'SET_FLORISTS_DATA': {
      return updateObject(state, {
        floristsList: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
