/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
// Components
import CustomButton from './CustomButton';

const ViewToggle = ({ children, activeView, onViewChange }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(activeView);

  useEffect(() => {
    setCurrentActiveTab(activeView);
  }, [activeView]);

  return (
    <div className="view-toggle-wrapper">
      <div className="view-btns-wrapper">
        {children.map((el) => {
          return (
            <CustomButton
              key={`toggle-btn-${el.props.viewIndex}`}
              id={el.props.viewIndex}
              name={el.props.label}
              handleClick={() => onViewChange(el.props.viewIndex, el.props.url)}
              disabled={false}
              type={currentActiveTab === el.props.viewIndex ? 'active-view' : 'inactive-view'}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ViewToggle;
