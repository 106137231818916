import { apiFloristGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OrdersListService {
  static getordersListData(data, ...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.ordersListData, ...extraParams),
      params: data,
    });
  }

  static getFloristsList(params, ...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFloristsList, ...extraParams),
      params,
    });
  }

  static getCompletedOrders(params, ...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.completedOrders, ...extraParams),
      params,
    });
  }

  static assignFloristStatus(params, ...extraParams) {
    return apiFloristGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.assignFlorist, ...extraParams),
      params,
    });
  }

  static changeOrderPreparationStatus(data, ...extraParams) {
    return apiFloristGatewayService({
      method: 'PUT',
      url: setEndPoint(endPoints.changeOrderPreparationStatus, ...extraParams),
      data,
    });
  }

  static getGroupedBySku(params, ...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.groupedBy, ...extraParams),
      params,
    });
  }

  static getGroupedByZone(params, ...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.groupedBy, ...extraParams),
      params,
    });
  }
  static NotDoneList(...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.notDoneReasonsList, ...extraParams),
    });
  }
}
export default OrdersListService;
