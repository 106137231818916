import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  formatDateTime,
  convertObjectIntoQueryParams,
  convertQueryParamsIntoObject,
} from 'constants/Helpers';
import { useOnScreen } from 'components/common/hooks/useOnScreen';
// Components
import LoadingOverlay from 'components/common/LoadingOverlay';
import OrdersList from 'components/orders/OrdersList';
// Selectors
import {
  getorderDetailsData,
  getDeliveryData,
  getPageCount,
} from 'store/orders/OrdersListSelectors';
// API
import OrdersListService from 'services/OrdersListService';

const CompletedOrdersPage = () => {
  const ordersListData = useSelector((state) => getorderDetailsData({ state })),
    deliveryDate = useSelector((state) => getDeliveryData({ state })),
    pageCount = useSelector((state) => getPageCount({ state })),
    [showLoader, setShowLoader] = useState(false),
    [initialParams, setInitialParams] = useState({
      page: 1,
      deliveryDate: formatDateTime(deliveryDate),
      floristId: -1,
      sortby: 3,
    }),
    [counter, setCounter] = useState(1),
    ref = useRef(),
    onScreen = useOnScreen(ref, '200px'),
    dispatch = useDispatch(),
    navigate = useNavigate(),
    location = useLocation(),
    queryObj = location.search
      ? convertQueryParamsIntoObject(location.search)
      : { ...initialParams };

  const fetchCompletedOtrders = async (params, page) => {
    setShowLoader(true);
    try {
      const res = await OrdersListService.getCompletedOrders(params);
      dispatch({
        type: 'SET_ORDERS_LIST_DATA',
        payload: {
          ordersList: res.data.data,
          pageCount: res?.data?.pageCount,
          isFlorist: page ? true : false,
        },
      });
      navigate({
        pathname: '/completed-orders',
        search: convertObjectIntoQueryParams(params),
      });
      setCounter((prev) => prev + 1);
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
    }
  };

  const fetchMoreData = async (params, page) => {
    let clonedParams = { ...params, page: counter };
    if (page) {
      setCounter(1);
      clonedParams = { ...params, page: 1 };
    }

    if (page || counter <= pageCount) {
      fetchCompletedOtrders(clonedParams, page);
    }
  };

  const fetchData = (page) => {
    setInitialParams(queryObj);
    return fetchMoreData(queryObj, page);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showLoader && <LoadingOverlay />}
      <OrdersList
        ordersListData={ordersListData}
        fetchMoreData={fetchData}
        initialParams={initialParams}
        onScreen={onScreen}
      />
    </>
  );
};

export default CompletedOrdersPage;
