import React from 'react';

const BackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="svgicon" viewBox="0 0 512 512">
    <title>Arrow Back</title>
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M244 400L100 256l144-144M120 256h292"
      className="svgicon-fill-none"
    />
  </svg>
);

export default BackIcon;
