import React, { useState, useEffect } from 'react';
// import { dateFormat } from 'constants/Helpers';
import moment from 'moment';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Calendar } from 'react-date-range';
import CustomInput from './CustomInput';

const DatePicker = ({
  id,
  label,
  value,
  showSingleDatePicker,
  setShowSingleDatePicker,
  handleChange,
  customProps,
}) => {
  const [singleDateVal, setSingleDateVal] = useState(new Date());

  const handleSingleDateRange = (item) => {
    handleChange(item);
    setSingleDateVal(item);
    setShowSingleDatePicker(false);
  };

  useEffect(() => {
    setSingleDateVal(new Date(value));
  }, [value]);

  return (
    <div className="form-group filter-select">
      <label className="font-weight-bold" htmlFor={id}>
        {label}
      </label>
      <div className="single-filter-date">
        <CustomInput
          id={id}
          customCss="form-control digits"
          value={`${moment(new Date(singleDateVal)).format('MMM DD, YYYY')}`}
          handleClick={() => setShowSingleDatePicker(true)}
          readOnly
        />
      </div>
      {showSingleDatePicker && (
        <ClickAwayListener onClickAway={() => setShowSingleDatePicker(false)}>
          <div className="single-date-picker-modal">
            <Calendar
              date={singleDateVal}
              onChange={(item) => handleSingleDateRange(item)}
              direction={window.screen.width < 500 ? 'vertical' : 'horizontal'}
              moveRangeOnFirstSelection={false}
              {...customProps}
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DatePicker;
