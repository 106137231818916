import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
// Components
import OrdersList from 'headflorist/components/orders/OrdersList';
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
import ViewToggle from 'headflorist/components/common/ViewToggle';
import SortGroupSection from 'headflorist/components/orders/SortGroupSection';
import BySku from 'headflorist/components/groupedBySku/BySku';
import ByZone from 'headflorist/components/groupedByZone/ByZone';
// Helpers
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  formatDateTime,
  formatDateForInput,
} from 'constants/Helpers';
import { useOnScreen } from 'headflorist/components/common/hooks/useOnScreen';
// Selectors
import { getIsLoaderOpen } from 'headflorist/store/app/AppSelectors';
import {
  getDeliveryData,
  getorderDetailsData,
  getPageCount,
  getDeliveryTimeSlot,
  getGroupBySkuData,
  getGroupByZoneData,
} from 'headflorist/store/orders/OrdersListSelectors';
// API
import OrdersListService from 'headflorist/services/OrdersListService';
import TimeSlotsService from 'headflorist/services/TimeSlotsService';
// Actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'headflorist/store/app/AppActions';
// urls
import { getOrdersPageUrl, getMyTasksPageUrl } from 'constants/AppUrls';
import { getFromLocalStorage } from 'headflorist/helpers/LocalStorage';
const HFMyTasksPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ref = useRef();
  const ordersListData = useSelector((state) => getorderDetailsData({ state }));
  const deliveryDate = useSelector((state) => getDeliveryData({ state }));
  const deliveryTimeSlot = useSelector((state) => getDeliveryTimeSlot({ state }));
  const pageCount = useSelector((state) => getPageCount({ state }));
  const [counter, setCounter] = useState(1);
  const isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state }));
  const queryObj = location.search ? convertQueryParamsIntoObject(location.search) : {};
  const onScreen = useOnScreen(ref, '200px');
  const [initialParams, setInitialParams] = useState({
    page: 1,
    deliveryDate: formatDateTime(deliveryDate),
    floristId: -1,
    sortBy: 6,
    timeName: deliveryTimeSlot,
    FloristOrdersGroupBy: -1,
  });
  const [currentView, setCurrentView] = useState(1);
  const floristId =
    getFromLocalStorage('floristId') || jwt_decode(getFromLocalStorage('token'))?.id;
  const groupBySkuData = useSelector((state) => getGroupBySkuData({ state }));
  const groupByZoneData = useSelector((state) => getGroupByZoneData({ state }));

  const fetchMoreData = async (params, page, isSortBy = false) => {
    let clonedParams = { ...params, page: counter };
    if (page) {
      setCounter(1);
      clonedParams = { ...params, page: 1, floristId: +floristId };
    }

    if (page || counter <= pageCount || isSortBy) {
      dispatch(setLoaderOpenTrue());
      try {
        switch (parseInt(params.FloristOrdersGroupBy, 10)) {
          case -1:
            const resDef = await OrdersListService.getordersListData(
              clonedParams,
              clonedParams.slotId
            );
            dispatch({
              type: 'SET_ORDERS_LIST_DATA',
              payload: {
                ordersList: resDef?.data?.data,
                pageCount: resDef?.data?.pageCount,
                isFlorist: page ? true : false,
              },
            });
            break;
          case 0:
            const resSKU = await OrdersListService.getGroupedBySku(
              clonedParams,
              clonedParams?.slotId
            );
            if (page) {
              dispatch({
                type: 'SET_GROUP_BY_SKU_DATA',
                payload: resSKU?.data,
              });
            } else {
              dispatch({
                type: 'SET_GROUP_BY_SKU_APPENDED_DATA',
                payload: resSKU?.data,
              });
            }
            break;
          case 1:
            const resZone = await OrdersListService.getGroupedByZone(
              clonedParams,
              clonedParams?.slotId
            );
            if (page) {
              dispatch({
                type: 'SET_GROUP_BY_ZONE_DATA',
                payload: resZone?.data,
              });
            } else {
              dispatch({
                type: 'SET_GROUP_BY_ZONE_APPENDED_DATA',
                payload: resZone?.data,
              });
            }
            break;

          default:
            break;
        }
        navigate({
          pathname: '/my-task',
          search: convertObjectIntoQueryParams(clonedParams),
        });
        setCounter((prev) => prev + 1);
        dispatch(setLoaderOpenFalse());
      } catch (err) {
        dispatch(setLoaderOpenFalse());
      }
    }
  };

  const fetchData = (page) => {
    setInitialParams(queryObj);
    return fetchMoreData(queryObj, page);
  };

  const fetchTimeSlots = async (date) => {
    const params = {
      deliveryDate: formatDateForInput(new Date(queryObj.deliveryDate)),
    };
    try {
      const res = await TimeSlotsService.getTimeSlotsByDate(params);
      if (res?.data && res?.data?.length > 0) {
        const item = res?.data?.[0]?.slots?.filter(
          (s) => s?.slotId === parseInt(queryObj?.slotId, 10)
        )?.[0];
        dispatch({
          type: 'SET_DELIVERY_TIME_SLOT',
          payload: `${item?.deliveryTimeName} (${item?.nickName})`,
        });
      }
    } catch (err) {
      // @TODO - Add datadog
    }
  };

  const onViewChange = (vIndx, vURL) => {
    setCurrentView(vIndx);
    navigate({
      pathname: vURL,
      search: convertObjectIntoQueryParams({
        slotId: queryObj?.slotId,
        sortBy: queryObj?.sortBy || 6,
        deliveryDate: queryObj?.deliveryDate,
        floristId: -1,
        searchKey: queryObj?.searchKey || '',
        FloristOrdersGroupBy: queryObj?.FloristOrdersGroupBy || -1,
      }),
    });
  };

  const handleGroupReturnedComponent = (groupBy) => {
    switch (parseInt(groupBy, 10)) {
      case -1:
        return (
          <OrdersList
            ordersListData={ordersListData}
            fetchMoreData={fetchData}
            initialParams={initialParams}
            onScreen={onScreen}
            isMyTask={true}
          />
        );
      case 0:
        return (
          <BySku
            navigate={navigate}
            fetchMoreData={fetchData}
            initialParams={initialParams}
            groupBySkuData={groupBySkuData}
            onScreen={onScreen}
            isLoaderOpen={isLoaderOpen}
            isMyTask={true}
          />
        );
      case 1:
        return (
          <ByZone
            navigate={navigate}
            fetchMoreData={fetchData}
            initialParams={initialParams}
            groupByZoneData={groupByZoneData}
            onScreen={onScreen}
            isLoaderOpen={isLoaderOpen}
            isMyTask={true}
          />
        );

      default:
        break;
    }
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj.FloristOrdersGroupBy]);

  useEffect(() => {
    if (queryObj.deliveryDate) {
      fetchTimeSlots(queryObj.deliveryDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryObj]);

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      <SortGroupSection
        fetchData={fetchMoreData}
        setInitialParams={setInitialParams}
        baseURL={getMyTasksPageUrl()}
      />
      <ViewToggle activeView={currentView} onViewChange={onViewChange}>
        <div label="My Tasks" url={getMyTasksPageUrl()} viewIndex={1} />
        <div label="All Orders" url={getOrdersPageUrl()} viewIndex={2} />
      </ViewToggle>
      {handleGroupReturnedComponent(initialParams.FloristOrdersGroupBy)}
      <div ref={ref} />
    </>
  );
};

export default HFMyTasksPage;
