import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getFromLocalStorage } from 'helpers/LocalStorage';
// Components
import CustomButton from './CustomButton';
import BackIcon from 'headflorist/assets/images/svg/BackIcon';
import ReloadIcon from 'headflorist/assets/images/svg/ReloadIcon';
import LogoutIcon from 'headflorist/assets/images/svg/LogoutIcon';
import HomeIcon from 'headflorist/assets/images/svg/HomeIcon';
import NotificationButton from './NotificationButton';
import NoDeviationNotifications from './NoDeviationNotifications';
import HeaderSearch from './HeaderSearch';
// Helpers
import {
  reloadPage,
  handleLogOut,
  handleBack,
  convertQueryParamsIntoObject,
} from 'constants/Helpers';
// Selectors
import { getFirebasePermissions } from 'store/app/AppSelectors';

const Header = () => {
  const navigate = useNavigate(),
    location = useLocation(),
    params = location.search ? convertQueryParamsIntoObject(location.search) : {},
    currentPage = location.pathname.split('/')[1] || 'home',
    opsID = getFromLocalStorage('opsId'),
    firebasePermissions = useSelector((state) => getFirebasePermissions({ state })),
    deviationEnabled = firebasePermissions?.enable_deviation?.filter(
      (ops) => ops.id === parseInt(opsID)
    )?.[0]?.enable;

  const leftColumnComponent = (page) => {
    switch (page) {
      case 'production':
        return (
          <>
            <div className="header-left-column">
              <CustomButton
                id="header-notifications"
                handleClick={() => navigate('/home')}
                type="header-icon"
                fontAwsome={<HomeIcon />}
              />
            </div>
            <div className="header-center-column">
              <HeaderSearch />
            </div>
          </>
        );
      case 'home':
        return (
          <>
            <div className="header-left-column">
              <CustomButton
                id="header-completedOrders"
                name="Completed Orders"
                handleClick={() => navigate('/completed-orders')}
                type="header"
              />
            </div>
            <div className="header-center-column">
              <HeaderSearch />
            </div>
          </>
        );
      case 'orderdetails':
        return (
          <div className="header-left-column">
            <div className="buttons-column">
              <CustomButton
                id="header-notifications"
                handleClick={() => handleBack()}
                type="header-icon"
                fontAwsome={<BackIcon />}
              />
              <CustomButton
                id="header-notifications"
                handleClick={() => navigate('/home')}
                type="header-icon"
                fontAwsome={<HomeIcon />}
              />
            </div>
          </div>
        );
      case 'orders':
      case 'my-task':
        return (
          <div className="header-left-column">
            <div className="buttons-column">
              <CustomButton
                id="header-notifications"
                handleClick={() => handleBack()}
                type="header-icon"
                fontAwsome={<BackIcon />}
              />
              <CustomButton
                id="header-notifications"
                handleClick={() => navigate('/home')}
                type="header-icon"
                fontAwsome={<HomeIcon />}
              />
            </div>
            <div className="header-center-column">
              <HeaderSearch />
            </div>
          </div>
        );
      case 'completed-orders':
        return (
          <div className="header-left-column">
            <CustomButton
              id="header-notifications"
              handleClick={() => navigate('/home')}
              type="header-icon"
              fontAwsome={<HomeIcon />}
            />
            <h5 className="header-selected-date">
              {moment(params.deliveryDate).format('DD/MM/YYYY')}
            </h5>
          </div>
        );
      case 'qualitycheck':
        return (
          <div className="header-left-column">
            <div className="buttons-column">
              <CustomButton
                id="header-notifications"
                handleClick={() => handleBack()}
                type="header-icon"
                fontAwsome={<BackIcon />}
              />
              <CustomButton
                id="header-notifications"
                handleClick={() => navigate('/home')}
                type="header-icon"
                fontAwsome={<HomeIcon />}
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={`header-wrapper no-bottom-margin`}>
      {leftColumnComponent(currentPage)}
      <div className="header-right-column">
        {currentPage !== 'completed-orders' &&
          (deviationEnabled ? <NotificationButton /> : <NoDeviationNotifications />)}
        <CustomButton
          id="header-notifications"
          handleClick={() => reloadPage()}
          type="header-icon"
          fontAwsome={<ReloadIcon />}
        />
        {(currentPage === 'home' || currentPage === 'production') && (
          <CustomButton
            id="header-notifications"
            handleClick={() => handleLogOut()}
            type="header-icon"
            fontAwsome={<LogoutIcon />}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
