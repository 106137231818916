import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class TimeSlotsService {
	static getTimeSlotsByDate(data, ...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.getTimeSlotByDate, ...extraParams),
			params: data,
		});
	}
}
export default TimeSlotsService;
