import moment from 'moment';

export const reloadPage = () => {
  window.location.reload();
};

export const handleLogOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('opsId');
  window.location.href = '/login';
};

export const convertQueryParamsIntoObject = (params) =>
  JSON.parse(
    '{"' + decodeURI(params.substring(1).replace(/&/g, '","').replace(/=/g, '":"')) + '"}'
  );

export const convertObjectIntoQueryParams = (object) =>
  '?' +
  Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join('&');

export const convertUnixToDate = (unix) => {
  const milliseconds = unix * 1000,
    dateObject = new Date(milliseconds);
  return dateObject;
};

export const handleBack = () => {
  window.history.back();
};

export const status = {
  0: 'Done',
  1: 'Assign',
  2: ['Done', 'Not Done'],
  3: { text: 'Start', enabled: true },
  4: 'Waiting for Picker',
  5: { text: 'Start', enabled: false },
  6: 'Assign',
  7: { text: 'Start', enabled: true },
  8: { text: 'Start', enabled: false },
};

export const colleagueStatus = {
  0: 'Done',
  1: '',
  2: 'Started',
  3: 'Assigned',
  4: 'Waiting for Picker',
  5: 'Assigned',
  6: '',
  7: 'Assigned',
  8: 'Assigned',
};
export const sortFilterData = [
  { value: '1', text: 'Assigned', urlPath: 'orders' },
  { value: '2', text: 'Unassigned', urlPath: 'orders' },
  { value: '3', text: 'Need Preparation', urlPath: 'orders' },
  { value: '4', text: 'No Preparation', urlPath: 'orders' },
  { value: '5', text: 'Need Input', urlPath: 'orders' },
];

export const formatDateForInput = (date) => moment(date).format('YYYY-MM-DD');

export const formatDateTime = (date) => `${formatDateForInput(date)}T00:00:00`;

export const convertToDateAndTime = (date) => {
  const dateObject = new Date(date);
  const humanDateFormat = dateObject.toLocaleString();
  return humanDateFormat;
};

export const dateFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = mm + '/' + dd + '/' + yyyy;
  return newDate;
};

export const isoStartFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00';
  return newDate;
};

export const isoFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00';
  return newDate;
};

export const isoEndFormat = (date) => {
  const dateFormat = new Date(date);
  const dd = String(dateFormat.getDate()).padStart(2, '0');
  const mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
  const yyyy = dateFormat.getFullYear();
  const newDate = yyyy + '-' + mm + '-' + dd + 'T23:59:59';
  return newDate;
};

export const hasPermission = (permissionsList, page, action) => {
  return permissionsList.some((item) => item.pageKey === page && item.actionKey === action);
};

export const preparationTypes = [
  { id: 0, name: 'Need Input', bgColor: '#FFF1DF', textColor: '#F4AA24' },
  { id: 1, name: 'Need Preparation', bgColor: '#C7F1FF', textColor: '#006782' },
  { id: 2, name: 'No Preparation', bgColor: '#FEFFD3', textColor: '#AC9100' },
];

export const dataURLtoFile = async (dataurl, fileName) => {
  const blob = await (await fetch(dataurl)).blob();
  return new File([blob], fileName, { type: blob.type });
};
