import React, { useState } from 'react';
import CustomButton from 'components/common/CustomButton';
import ProductDetails from './ProductDetails';
import FavoriteFlowers from './FavoriteFlowers';
import BasketModal from './BasketModal';

const CartDetailsCard = ({ cartItem }) => {
  const [isModalOpen, setIsOpenModal] = useState(false),
    [basketId, setBasketId] = useState('');

  const handleCartDetails = (id) => {
    setBasketId(id);
    setIsOpenModal((prev) => !prev);
  };
  return (
    <>
      {isModalOpen && (
        <BasketModal
          isModalOpen={isModalOpen}
          setIsOpenModal={setIsOpenModal}
          basketId={basketId}
        />
      )}
      <div className="orderDetails-cartItem-wrapper" key={cartItem?.productId}>
        <div className="card cartDetails-card">
          <div className="card-header">
            <div className="card-header-wrapper">
              <div className="cartDetails-div">
                {cartItem?.name ? (
                  <h6 className="cartDetails-title">
                    {cartItem?.name} {cartItem?.sku ? `(${cartItem?.sku})` : ''}
                  </h6>
                ) : null}
                {cartItem?.cakeMessage ? (
                  <h6 className="sku">Personalized message: {cartItem?.cakeMessage}</h6>
                ) : null}
                {cartItem?.qty ? <h6 className="cartDetails-qty"> Qty: {cartItem?.qty}</h6> : null}
              </div>
              <div className="cartDetails-div">
                <CustomButton
                  id="show-basket"
                  name="Show Basket Details"
                  handleClick={() => handleCartDetails(cartItem?.id)}
                  disabled={false}
                  type="basket"
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <ProductDetails proId={cartItem?.productId} />
          </div>
          <div className="card-footer">
            <FavoriteFlowers cartId={cartItem?.id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default CartDetailsCard;
