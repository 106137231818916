import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, BrowserRouter, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { registerationRoutes, RestrictedRoutes } from 'routing/RoutesConfig';
import { hasPermission } from 'constants/Helpers';
import { getFromLocalStorage } from 'helpers/LocalStorage';
// Components
import Header from 'components/common/Header';
import HFHeader from 'headflorist/components/common/Header';
import Tabs from 'components/common/Tabs';
import LoadingOverlay from 'components/common/LoadingOverlay';
// urls
import {
  getHomePageUrl,
  getLoginPageUrl,
  getOrdersPageUrl,
  getProductionHomePage,
} from 'constants/AppUrls';
// routing
import RestrictedRoute from 'routing/RestrictedRoute';
import RegistrationRoute from 'routing/RegistrationRoute';
// selectors
import {
  getIsLoggedIn,
  getPermissionsData,
  getHeadFloristEnabled,
  getFirebasePermissions,
} from 'store/app/AppSelectors';
// actions
import {
  getPermissions,
  setIsHeadFloristTrue,
  setIsHeadFloristFalse,
  setFirebasePermissions,
} from 'store/app/AppActions';
import { setWebSocketConnection } from './headflorist/store/app/AppActions';
// Firebase
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';
import { getValue, fetchAndActivate } from 'firebase/remote-config';

let rcDefaults = require('./remote_config_defaults.json');

// Firebase Starts

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FB_DATABASEURL,
  projectId: process.env.REACT_APP_FB_PROJECTID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FB_APPID,
  measurementId: process.env.REACT_APP_FB_MESSUREMENTID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 30;
remoteConfig.defaultConfig = rcDefaults;

// Firebase Ends

function RequiredAuth({ isLoggedIn, homePath, redirectTo }) {
  return isLoggedIn ? <Navigate to={homePath} /> : <Navigate to={redirectTo} />;
}

function App() {
  const isLoggedIn = useSelector((state) => getIsLoggedIn({ state }));
  const isHeadFloristEnabled = useSelector((state) => getHeadFloristEnabled({ state }));
  const permissions = useSelector((state) => getPermissionsData({ state }));
  const dispatch = useDispatch();
  const [enableProduction, setEnableProduction] = useState(false);
  const [fetchingRemote, setFetchingRemote] = useState(true);
  const authToken = getFromLocalStorage('token');
  const opsID = getFromLocalStorage('opsId');
  const firebasePermissions = useSelector((state) => getFirebasePermissions({ state }));
  const deviationEnabled = firebasePermissions?.enable_deviation?.filter(
    (ops) => ops.id === parseInt(opsID)
  )?.[0]?.enable;

  useEffect(() => {
    if (authToken && deviationEnabled) {
      dispatch(setWebSocketConnection());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviationEnabled]);

  const fetchRemoteConfig = async () => {
    await fetchAndActivate(remoteConfig)
      .then(async () => {
        setEnableProduction(
          (await getValue(
            remoteConfig,
            'florist_app_enable_production'
          )._value?.toLowerCase?.()) === 'true'
        );
        const isHFEnabled =
          (await getValue(
            remoteConfig,
            'florist_app_enable_headflorist'
          )._value?.toLowerCase?.()) === 'true';
        const isDeviationEnabled = (await getValue(remoteConfig, 'florist_app_enable_deviation'))
          ._value;
        const isEditDeviationEnabled = (
          await getValue(remoteConfig, 'florist_app_enable_edit_deviation')
        )._value;
        const isQualityCheckEnabled = (
          await getValue(remoteConfig, 'florist_app_enable_quality_check')
        )._value;
        dispatch(
          setFirebasePermissions({
            enable_deviation: JSON.parse(isDeviationEnabled)?.ops,
            enable_edit_deviation: JSON.parse(isEditDeviationEnabled)?.ops,
            enable_quality_check: JSON.parse(isQualityCheckEnabled)?.ops,
          })
        );
        if (isHFEnabled) {
          dispatch(setIsHeadFloristTrue());
        } else {
          dispatch(setIsHeadFloristFalse());
        }
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
      })
      .catch((err) => {
        // errorLogger('HomePage: Firebase Error: ', err);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getPermissions());
      const fetchData = async () => {
        await fetchRemoteConfig();
        setFetchingRemote(false);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <body className={`App ${isHeadFloristEnabled ? 'hf-App' : ''}`}></body>
        </Helmet>
        <div>
          <div className="page-wrapper page-body">
            <div className="page-body-wrapper">
              {isLoggedIn && !fetchingRemote && (
                <>
                  {isHeadFloristEnabled ? <HFHeader /> : <Header />}
                  {enableProduction && hasPermission(permissions, 'florist_production', 'view') && (
                    <Tabs>
                      <div label="Orders" url={getOrdersPageUrl()} />
                      <div label="Production" url={getProductionHomePage()} />
                    </Tabs>
                  )}
                </>
              )}
              <Routes>
                <Route
                  path="/"
                  element={
                    <RequiredAuth
                      isLoggedIn={isLoggedIn}
                      homePath={getHomePageUrl()}
                      redirectTo={getLoginPageUrl()}
                    />
                  }
                />
                {registerationRoutes.map((route, i) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <RegistrationRoute path={route.path} component={route.component} key={i} />
                    }
                  />
                ))}
                {RestrictedRoutes().map((route, i) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <RestrictedRoute
                        path={route.path}
                        component={fetchingRemote ? LoadingOverlay : route.component}
                        key={i}
                        exact={route.exact}
                      />
                    }
                  />
                ))}
              </Routes>
            </div>
          </div>
          <ToastContainer theme="colored" icon={false} />
        </div>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
