import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Helpers
import { convertObjectIntoQueryParams } from 'constants/Helpers';
// Components
import { toast } from 'react-toastify';
import RightCaret from 'headflorist/assets/images/svg/RightCaret';
import CustomButton from 'headflorist/components/common/CustomButton';

const JobCard = ({
  imgSrc,
  productName,
  jobID,
  totalTasks,
  unassignedTasks,
  setShowConfirmation,
  setAssignedNbr,
  setClickedImg,
  setIsLightBoxOpen,
}) => {
  const navigate = useNavigate();
  const [unassignedValue, setUnassignedValue] = useState(unassignedTasks);

  const handleAssignClick = () => {
    setAssignedNbr(unassignedValue);
    setShowConfirmation(true);
  };

  const handleUnassignedInputChange = (e) => {
    if ((e.target.value > 0 && e.target.value <= unassignedTasks) || e.target.value === '') {
      setUnassignedValue(e.target.value);
    } else {
      if (e.target.value > unassignedTasks) {
        toast.error(`Unassigned tasks cannot be greater than ${unassignedTasks}`);
      } else {
        toast.error(`Unassigned tasks cannot be ${e.target.value}`);
      }
    }
  };

  const handleMinusClick = () => {
    if (unassignedValue > 1) {
      setUnassignedValue((prevState) => parseInt(prevState) - 1);
    } else {
      toast.error(`Tasks to assign cannot be less than 1`);
    }
  };

  const handlePlusClick = () => {
    if (unassignedValue < unassignedTasks) {
      setUnassignedValue((prevState) => parseInt(prevState) + 1);
    } else {
      toast.error(`Tasks to assign cannot be more than ${unassignedTasks}`);
    }
  };

  const handleNextClick = () => {
    const params = {
      jobID,
    };
    navigate({
      pathname: `/start`,
      search: convertObjectIntoQueryParams(params),
    });
  };

  return (
    <div className="job-card card">
      <div className="card-column">
        <img
          src={imgSrc}
          alt="product"
          className="product-img"
          onClick={() => {
            setClickedImg(imgSrc);
            setIsLightBoxOpen(true);
          }}
        />
      </div>
      <div className="card-column">
        <p>{productName}</p>
      </div>
      <div className="card-column">
        <p>{jobID}</p>
      </div>
      <div className="card-column">
        <p>{totalTasks}</p>
      </div>
      <div className="card-column">
        {unassignedTasks !== 0 && (
          <>
            <div className="plus-minus-container">
              <CustomButton id="minus-btn" name="-" handleClick={handleMinusClick} type="minus" />
              <input type="text" value={unassignedValue} onChange={handleUnassignedInputChange} />
              <CustomButton id="plus-btn" name="+" handleClick={handlePlusClick} type="plus" />
            </div>
          </>
        )}
      </div>
      <div className="card-column">
        {unassignedTasks !== 0 && (
          <CustomButton
            id="assign-btn"
            name="Assign"
            handleClick={handleAssignClick}
            type="assign"
          />
        )}
      </div>
      <div className="card-column caret">
        <CustomButton
          id="assign-btn"
          handleClick={handleNextClick}
          fontAwsome={<RightCaret />}
          type="rightCaret"
        />
      </div>
    </div>
  );
};

export default JobCard;
