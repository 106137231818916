import React from 'react';

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="card">
        <div className="loading-spinner"></div>
        &nbsp; <p>Please wait..</p>
      </div>
    </div>
  );
};

export default LoadingOverlay;
