import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// Components
import BySku from 'components/groupedBySku/BySku';
import LoadingOverlay from 'components/common/LoadingOverlay';
// Helpers
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  formatDateTime,
} from 'constants/Helpers';
import { useOnScreen } from 'components/common/hooks/useOnScreen';
// Actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';
// Selectors
import {
  getGroupBySkuData,
  getGroupBySkuPageCount,
  getDeliveryData,
  getDeliveryTimeSlot,
} from 'store/orders/OrdersListSelectors';
import { getIsLoaderOpen } from 'store/app/AppSelectors';
// API
import OrdersListService from 'services/OrdersListService';

const GroupedSkuPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const ref = useRef();
  const groupBySkuData = useSelector((state) => getGroupBySkuData({ state }));
  const isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state }));
  const pageCount = useSelector((state) => getGroupBySkuPageCount({ state }));
  const deliveryDate = useSelector((state) => getDeliveryData({ state }));
  const deliveryTimeSlot = useSelector((state) => getDeliveryTimeSlot({ state }));
  const queryObj = location.search ? convertQueryParamsIntoObject(location.search) : {};
  const onScreen = useOnScreen(ref, '200px');
  const [counter, setCounter] = useState(1);
  const [initialParams, setInitialParams] = useState({
    page: 1,
    deliveryDate: formatDateTime(deliveryDate),
    floristId: -1,
    sortby: 3,
    timeName: deliveryTimeSlot,
  });
  const dispatch = useDispatch();

  // const onStatusChanged = (index, newStatus, subIndex) => {
  //   var items = [...groupBySkuData];
  //   items[index].ordersList[subIndex].preparationStatus = newStatus;
  //   dispatch({
  //     type: 'SET_GROUP_BY_SKU_DATA',
  //     payload: items,
  //   });
  // };

  const fetchMoreData = async (params, page) => {
    let clonedParams = { ...params, page: counter };
    if (page) {
      setCounter(1);
      clonedParams = { ...params, page: 1 };
    }

    if (page || counter <= pageCount) {
      dispatch(setLoaderOpenTrue());
      try {
        const res = await OrdersListService.getGroupedBySku(clonedParams);
        if (page) {
          dispatch({
            type: 'SET_GROUP_BY_SKU_DATA',
            payload: res.data,
          });
        } else {
          dispatch({
            type: 'SET_GROUP_BY_SKU_APPENDED_DATA',
            payload: res.data,
          });
        }
        navigate({
          pathname: '/groupedBySKU',
          search: convertObjectIntoQueryParams(clonedParams),
        });
        setCounter((prev) => prev + 1);
        dispatch(setLoaderOpenFalse());
      } catch (err) {
        dispatch(setLoaderOpenFalse());
      }
    }
  };

  const fetchData = (page) => {
    setInitialParams(queryObj);
    return fetchMoreData(queryObj, page);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      <BySku
        navigate={navigate}
        fetchMoreData={fetchData}
        initialParams={initialParams}
        groupBySkuData={groupBySkuData}
        onScreen={onScreen}
        isLoaderOpen={isLoaderOpen}
      />
      <div ref={ref} />
    </>
  );
};

export default GroupedSkuPage;
