import CustomButton from 'headflorist/components/common/CustomButton';
import OrdersListService from 'headflorist/services/OrdersListService';
import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';

const NotDoneModal = ({ isModalOpen, setIsOpenModal, orderData, fetchData }) => {
  const [reasonsList, setReasonsList] = useState([]);
  const [reasonParams, setReasonParams] = useState({ cancelReason: -1, status: 1 });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getNotDoneList = async () => {
    const res = await OrdersListService.NotDoneList();
    setReasonsList(res.data);
  };
  const handleChangeReason = (item) => {
    setReasonParams({
      cancelReason: item?.id,
      status: 1,
    });
  };
  const handelProceed = async () => {
    setIsSubmitting(true);
    try {
      await OrdersListService.changeOrderPreparationStatus(reasonParams, orderData?.id);
      setIsOpenModal(false);
      setTimeout(() => fetchData(), 1000);

      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      if (error.response && error.response.status === 400) {
        // Handle 400 error response
        toast.error(error?.response?.data?.message);
      } else {
        // Handle other error responses
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getNotDoneList();
  }, []);
  return (
    <div className="not-done-modal-wrapper">
      {isModalOpen && (
        <Modal
          className="not-done-modal modal-lg"
          isOpen={isModalOpen}
          toggle={() => setIsOpenModal((prev) => !prev)}
        >
          <ModalHeader>Are you sure you want to mark all the tasks as Not done? </ModalHeader>
          <ModalBody>
            <div className="not-done-container">
              {reasonsList && (
                <ul className="reason-list">
                  {reasonsList?.map((item) => (
                    <li className="reason-item" key={item?.id}>
                      <div className="custom-radio-container">
                        <input
                          type="radio"
                          id={`reason-${item?.id}`}
                          name="reason"
                          value={item?.reason}
                          onChange={() => handleChangeReason(item)}
                        />
                        <div className="custom-radio"></div>
                      </div>
                      <label
                        htmlFor={`reason-${item?.id}`}
                        onClick={() => handleChangeReason(item)}
                      >
                        {item?.reason}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="fixed-footer">
              <CustomButton
                id={'modal-cancel-btn'}
                name={'Cancel'}
                handleClick={() => setIsOpenModal((prev) => !prev)}
                disabled={false}
                type={'cancel'}
              />
              <CustomButton
                id={'modal-assign-btn'}
                name={'Proceed'}
                handleClick={handelProceed}
                disabled={reasonParams?.cancelReason < 0 || isSubmitting}
                type={'assign'}
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default NotDoneModal;
