import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import SearchIcon from 'assets/images/svg/SearchIcon';
// Helpers
import { formatDateTime, convertQueryParamsIntoObject } from 'constants/Helpers';
import { isoStartFormat, isoEndFormat } from 'constants/Helpers';
// Selectors
import { getDeliveryData } from 'store/orders/OrdersListSelectors';
// Actions
import { setIsSearchingTrue, setOrdersListData } from 'store/orders/OrdersListActions';
import { getUnassignedBatchesData } from 'store/production/ProductionActions';

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const deliveryDate = useSelector((state) => getDeliveryData({ state }));
  const params = location.search ? convertQueryParamsIntoObject(location.search) : {};
  const [searchText, setSearchText] = useState('');
  const currentPage = location.pathname.slice(1);
  const navigate = useNavigate();

  const getHandler = (page, e) => {
    switch (page) {
      case 'home':
      case 'headflorist/home':
        return handleSubmit(e);
      case 'orders':
      case 'groupedByZone':
      case 'groupedBySKU':
      case 'headflorist/orders':
      case 'headflorist/groupedByZone':
      case 'headflorist/groupedBySKU':
        return handleOrdersSubmit(e);
      case 'production':
        return handleProductionSearch(e);
      default:
        break;
    }
  };

  const placeholderHandler = (page) => {
    switch (page) {
      case 'home':
      case 'orders':
      case 'groupedByZone':
      case 'groupedBySKU':
      case 'headflorist/home':
      case 'headflorist/orders':
      case 'headflorist/groupedByZone':
      case 'headflorist/groupedBySKU':
        return 'Search by order ID or SKU';
      case 'production':
        return 'Search by Job ID or Product Name';
      default:
        break;
    }
  };

  const handleProductionSearch = (e) => {
    e.preventDefault();
    if (searchText !== '') {
      dispatch(
        getUnassignedBatchesData(
          {
            jobDetails: searchText.trim(),
          },
          navigate
        )
      );
    } else {
      dispatch(
        getUnassignedBatchesData(
          {
            productionStartDate: isoStartFormat(new Date()),
            productionEndDate: isoEndFormat(new Date()),
          },
          navigate
        )
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsSearchingTrue());
    dispatch(
      setOrdersListData(
        {
          sortby: -1,
          deliveryDate: formatDateTime(deliveryDate),
          floristId: -1,
          searchKey: searchText,
        },
        -1,
        navigate
      )
    );
  };

  const handleOrdersSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setOrdersListData(
        { ...params, page: 1, floristId: -1, searchKey: searchText },
        params.slotId,
        navigate
      )
    );
  };

  return (
    <form className="search-form" onSubmit={(e) => getHandler(currentPage, e)}>
      <input
        id="search-header-input"
        placeholder={placeholderHandler(currentPage)}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      <SearchIcon />
    </form>
  );
};

export default HeaderSearch;
