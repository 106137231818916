import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// Components
import ChangeStatusBtn from './ChangeStatusBtn';
import Lightbox from 'react-image-lightbox';
import StarIcon from 'headflorist/assets/images/svg/StarIcon';
import WarningIcon from 'headflorist/assets/images/svg/WarningIcon';
// Helpers
import { colleagueStatus, preparationTypes } from 'constants/Helpers';
// Selectors
import { getIsLoaderOpen } from 'headflorist/store/app/AppSelectors';
import { getFirebasePermissions } from 'store/app/AppSelectors';
// Localstorage
import { getFromLocalStorage } from 'headflorist/helpers/LocalStorage';

const OrdersList = ({
  ordersListData,
  fetchMoreData,
  fetchFlorists,
  initialParams,
  onScreen,
  isMyTask,
}) => {
  const navigate = useNavigate();
  const [isLightBox, setIsLightBox] = useState(false);
  const [clickedImg, setClickedImg] = useState('');
  const isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state }));
  const firebasePermissions = useSelector((state) => getFirebasePermissions({ state }));
  const opsID = getFromLocalStorage('opsId');
  const editDeviationEnabled = firebasePermissions?.enable_edit_deviation?.filter(
    (ops) => ops.id === parseInt(opsID)
  )?.[0]?.enable;

  useEffect(() => {
    if (onScreen && !isLoaderOpen) {
      fetchMoreData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen]);

  return (
    <>
      <div className="orderWrapper">
        {ordersListData?.length > 0 ? (
          <>
            {ordersListData?.map((item, i) => {
              let currentPrepType = preparationTypes.find(
                (pt) => parseInt(pt.id, 10) === parseInt(item.preparationType, 10)
              );
              return (
                <div className="card orderCard" key={i}>
                  <div className="card-header orderHeader">
                    <div className="assign-florist">
                      <h6 className="mb-0 order-card-header">
                        {' '}
                        Order{' '}
                        {item?.orderPriority === 1 && <span className="order-priority">!</span>}
                      </h6>
                      <h6
                        onClick={() => navigate(`/orderdetails/${item?.id}`)}
                        className="order-card-desc"
                      >
                        {item?.id}
                      </h6>
                      {item?.isNwDelivery ? <div className="nw-order">NW</div> : null}
                      {item?.productionLine === 1 && <StarIcon cssClass="star-icon" />}
                      {item?.membershipType === 1 && <div className="vip-order">VIP</div>}
                    </div>
                    <div className="assign-florist">
                      <h6 className="mb-0 order-card-header">Items</h6>
                      <h6 className="order-card-desc">{item?.cartData?.length}</h6>
                    </div>
                    <div className="assign-florist">
                      <h6 className="mb-0 order-card-header">Assigned by</h6>
                      <h6 className="order-card-desc">
                        {item?.floristAssignedByDto ? item?.floristAssignedByDto?.name : ''}
                      </h6>
                    </div>
                    <div className="assign-florist">
                      <h6 className="mb-0 order-card-header">Status</h6>
                      <h6 className="order-card-desc prep-status">
                        {item?.preparationStatus ? colleagueStatus[item?.preparationStatus] : ''}
                      </h6>
                    </div>
                    <div className="assign-florist">
                      <h6 className="mb-0 order-card-header">Assigned to</h6>
                      <h6 className="mb-0 order-card-desc">
                        {item?.floristDto ? item?.floristDto?.name : ''}
                      </h6>
                    </div>
                    <div className="statusBtn">
                      <ChangeStatusBtn
                        item={item}
                        fetchMoreData={fetchMoreData}
                        fetchFlorists={fetchFlorists}
                        initialParams={initialParams}
                        details={false}
                        // showAllCTA={isMyTask}
                        showAllCTA={true}
                      />
                    </div>
                  </div>
                  {editDeviationEnabled &&
                    item?.preparedPreviously &&
                    item?.preparationStatus !== 0 && (
                      <div className="header-disclaimer">
                        <WarningIcon />{' '}
                        <p>
                          This order was prepared once. Check with supervisor before moving ahead.
                        </p>
                      </div>
                    )}
                  <div className="card-body" onClick={() => navigate(`/orderdetails/${item?.id}`)}>
                    <div className="card-body-content">
                      <div className="card-body-left">
                        {item?.cartData
                          .filter((cart) => cart?.serviceType !== 1)
                          .map((el, i) => {
                            const wrapItem = item?.cartData?.find(
                              (cart) => cart?.serviceType === 1 && cart?.parentId === el?.id
                            );
                            return (
                              <div key={el?.id} className="cart-list">
                                <div className="cart-item-container">
                                  <div>
                                    <img
                                      className="product-img"
                                      alt="product"
                                      src={el?.productImageFullPath}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setClickedImg(el?.productImageFullPath);
                                        setIsLightBox(true);
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h6 className={el?.qty > 1 ? 'red' : ''}>
                                      <span>{el?.qty}X</span> - {el?.productSku}
                                    </h6>
                                    {el?.cakeMessage && (
                                      <h3 className="personalized-msg">
                                        <span>Personalized Msg:</span> {el?.cakeMessage}
                                      </h3>
                                    )}
                                  </div>
                                </div>
                                {wrapItem && (
                                  <div className="gift-wrap">
                                    <span className="plus-wrap">+</span>
                                    <img
                                      className="product-img"
                                      alt="product"
                                      src={wrapItem?.productImageFullPath}
                                    />{' '}
                                    <h3 className="gift-wrap-txt">
                                      <span>{wrapItem?.qty}X Wrap - </span>
                                      {wrapItem?.proName}
                                    </h3>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div className="card-body-left tags-left-col">
                        <div
                          className="prepType-btn"
                          style={{
                            backgroundColor: currentPrepType?.bgColor,
                          }}
                        >
                          <p
                            className=""
                            style={{
                              color: currentPrepType?.textColor,
                            }}
                          >
                            {currentPrepType?.name}
                          </p>
                        </div>
                        {editDeviationEnabled &&
                          item?.preparedPreviously &&
                          item?.preparationStatus !== 0 && (
                            <div className="prepType-btn prev-prepared-btn">
                              <p>Prepared Once</p>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {isLightBox && (
              <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />
            )}
          </>
        ) : (
          <h4 className="no-orders-title">No orders</h4>
        )}
      </div>
    </>
  );
};

export default OrdersList;
