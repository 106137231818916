import { apiFloristGatewayService, apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class OrderDetailsService {
  static getOrderDetails(...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.orderDetails, ...extraParams),
    });
  }

  static getCartDetails(...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.cartDetails, ...extraParams),
    });
  }

  static getInventoryDetails(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.inventoryDetails, ...extraParams),
    });
  }

  static getBasketDetails(...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.basketDetails, ...extraParams),
    });
  }

  //favorite flowers
  static getFavoriteFlowersData(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getFavFlowersData, ...extraParams),
    });
  }

  // Deviation
  static getDeviationReasonsData(...extraParams) {
    return apiFloristGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getDeviationReasons, ...extraParams),
    });
  }

  static addDeviation(data, ...extraParams) {
    return apiFloristGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.addDeviation, ...extraParams),
      data,
    });
  }

  static editDeviation(data, ...extraParams) {
    return apiFloristGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.editDeviation, ...extraParams),
      data,
    });
  }

  //QC images
  static uploadQcImage(data, ...extraParams) {
    return apiFloristGatewayService({
      method: 'POST',
      url: setEndPoint(endPoints.uploadQcImage, ...extraParams),
      data,
    });
  }

  static getQcImages(...extraParams) {
    return apiGatewayService({
      method: 'GET',
      url: setEndPoint(endPoints.getQcImages, ...extraParams),
    });
  }
}
export default OrderDetailsService;
