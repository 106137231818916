// Helpers
import { convertObjectIntoQueryParams } from 'constants/Helpers';
// API
import ProductionService from 'headflorist/services/ProductionService';
// Actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'headflorist/store/app/AppActions';

export const getUnassignedBatchesData = (params, navigate) => async (dispatch) => {
  dispatch(setLoaderOpenTrue());
  try {
    const res = await ProductionService.getUnassignedBatches(params);
    dispatch({
      type: 'SET_PRODUCTION_DATA',
      payload: res?.data,
    });
    navigate({
      pathname: '/production',
      search: convertObjectIntoQueryParams(params),
    });
    dispatch(setLoaderOpenFalse());
  } catch (e) {
    dispatch(setLoaderOpenFalse());
  }
};
