const CustomInput = ({
  id,
  value,
  handleChange,
  handleClick,
  type,
  disabled,
  customCss,
  readOnly,
  placeholder,
}) => {
  return (
    <div className="custom-input-wrapper">
      <input
        className={`custom-input ${customCss}`}
        id={id}
        value={value}
        onChange={handleChange}
        onClick={handleClick}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomInput;
