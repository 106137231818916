import { apiGatewayService } from './apiService/HttpService';
import { setEndPoint } from './apiService/SetEndPoint';
import { endPoints } from './apiService/EndPoints';

class NotificationsService {
	static getNotificationsList(...extraParams) {
		return apiGatewayService({
			method: 'GET',
			url: setEndPoint(endPoints.getNotificationsList, ...extraParams),
		});
	}

	static getReadNotification(...extraParams) {
		return apiGatewayService({
			method: 'PUT',
			url: setEndPoint(endPoints.getReadNotification, ...extraParams),
		});
	}
}
export default NotificationsService;
