import { updateObject } from '../Utility';

const initialState = {
  unassignedList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCTION_DATA': {
      return updateObject(state, {
        unassignedList: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
