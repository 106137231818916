import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
// Components
import CustomButton from './CustomButton';
import BackIcon from 'assets/images/svg/BackIcon';
import ReloadIcon from 'assets/images/svg/ReloadIcon';
import LogoutIcon from 'assets/images/svg/LogoutIcon';
import HomeIcon from 'assets/images/svg/HomeIcon';
import NotificationButton from './NotificationButton';
import CustomSelect from './CustomSelect';
import HeaderSearch from './HeaderSearch';
// Helpers
import {
  reloadPage,
  handleLogOut,
  handleBack,
  convertQueryParamsIntoObject,
} from 'constants/Helpers';
// Selectors
import { getDeliveryTimeSlot } from 'store/orders/OrdersListSelectors';

const Header = () => {
  const deliveryTimeSlot = useSelector((state) => getDeliveryTimeSlot({ state })),
    navigate = useNavigate(),
    location = useLocation(),
    params = location.search ? convertQueryParamsIntoObject(location.search) : {},
    currentPage = location.pathname.split('/')?.[1] || 'home';

  const handleGroupBy = (e) => {
    const clonedParams = { ...params, groupedBy: e.itemData.value, page: 1 };
    const queryString =
      '?' +
      Object.keys(clonedParams)
        .map((key) => `${key}=${clonedParams[key]}`)
        .join('&');
    navigate({
      pathname: `/${e.itemData.value}`,
      search: queryString,
    });
  };

  const leftColumnComponent = (page) => {
    switch (page) {
      case 'production':
        return (
          <>
            <div className="header-left-column">
              <CustomButton
                id="header-notifications"
                handleClick={() => navigate('/home')}
                type="header-icon"
                fontAwsome={<HomeIcon />}
              />
            </div>
            <div className="header-center-column">
              <HeaderSearch />
            </div>
          </>
        );
      case 'home':
        return (
          <>
            <div className="header-left-column">
              <CustomButton
                id="header-completedOrders"
                name="Completed Orders"
                handleClick={() => navigate('/completed-orders')}
                type="header"
              />
            </div>
            <div className="header-center-column">
              <HeaderSearch />
            </div>
          </>
        );
      case 'orderdetails':
        return (
          <div className="header-left-column">
            <CustomButton
              id="header-notifications"
              handleClick={() => handleBack()}
              type="header-icon"
              fontAwsome={<BackIcon />}
            />
            <CustomButton
              id="header-notifications"
              handleClick={() => navigate('/home')}
              type="header-icon"
              fontAwsome={<HomeIcon />}
            />
          </div>
        );
      case 'groupedBySKU':
      case 'groupedByZone':
      case 'orders':
        return (
          <div className="header-left-column">
            <CustomButton
              id="header-notifications"
              handleClick={() => navigate('/home')}
              type="header-icon"
              fontAwsome={<HomeIcon />}
            />
            <h5 className="header-selected-date">
              {moment(params.deliveryDate).format('DD/MM/YYYY')}
            </h5>
            <h5 className="header-selected-slot">{deliveryTimeSlot}</h5>
            <div className="header-center-column">
              <HeaderSearch />
            </div>
          </div>
        );
      case 'completed-orders':
        return (
          <div className="header-left-column">
            <CustomButton
              id="header-notifications"
              handleClick={() => navigate('/home')}
              type="header-icon"
              fontAwsome={<HomeIcon />}
            />
            <h5 className="header-selected-date">
              {moment(params.deliveryDate).format('DD/MM/YYYY')}
            </h5>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className={`header-wrapper no-bottom-margin`}>
      {leftColumnComponent(currentPage)}
      {(currentPage === 'orders' ||
        currentPage === 'groupedByZone' ||
        currentPage === 'groupedBySKU') && (
        <div className="header-groupBy-wrapper">
          <CustomSelect
            id="countryLogin"
            placeholder="Group by"
            listData={[
              { value: 'groupedBySKU', text: 'Product SKU' },
              { value: 'groupedByZone', text: 'Delivery Zone' },
              { value: 'orders', text: 'Default' },
            ]}
            textPropName="text"
            valuePropName="value"
            handleChange={handleGroupBy}
            selectedValue={params.groupedBy}
            allowFiltering={false}
          />
        </div>
      )}
      <div className="header-right-column">
        {currentPage !== 'completed-orders' && <NotificationButton />}
        <CustomButton
          id="header-notifications"
          handleClick={() => reloadPage()}
          type="header-icon"
          fontAwsome={<ReloadIcon />}
        />
        {(currentPage === 'home' || currentPage === 'production') && (
          <CustomButton
            id="header-notifications"
            handleClick={() => handleLogOut()}
            type="header-icon"
            fontAwsome={<LogoutIcon />}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
