import { combineReducers } from 'redux';
import app from './app/AppReducer';
import ordersList from './orders/OrdersListReducer';
import totalOrderDetails from './orderDetails/OrderDetailsReducer';
import production from './production/ProductionReducer';

const rootReducer = combineReducers({
  app,
  ordersList,
  totalOrderDetails,
  production,
});

export default rootReducer;
