import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import SearchIcon from 'headflorist/assets/images/svg/SearchIcon';
// Helpers
import {
  formatDateTime,
  convertQueryParamsIntoObject,
  isoStartFormat,
  isoEndFormat,
} from 'constants/Helpers';
// Selectors
import { getDeliveryData } from 'headflorist/store/orders/OrdersListSelectors';
// Actions
import { setIsSearchingTrue, setOrdersListData } from 'headflorist/store/orders/OrdersListActions';
import { getUnassignedBatchesData } from 'headflorist/store/production/ProductionActions';

const HeaderSearch = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const deliveryDate = useSelector((state) => getDeliveryData({ state }));
  const params = location.search ? convertQueryParamsIntoObject(location.search) : {};
  const [searchText, setSearchText] = useState('');
  const currentPage = location.pathname.slice(1);
  const navigate = useNavigate();

  const getHandler = (page, e) => {
    switch (page) {
      case 'home':
        return handleSubmit(e);
      case 'orders':
      case 'my-task':
        return handleOrdersSubmit(e);
      case 'production':
        return handleProductionSearch(e);
      default:
        break;
    }
  };

  const placeholderHandler = (page) => {
    switch (page) {
      case 'home':
      case 'orders':
      case 'my-task':
        return 'Search by order ID or SKU';
      case 'production':
        return 'Search by Job ID or Product Name';
      default:
        break;
    }
  };

  const handleProductionSearch = (e) => {
    e.preventDefault();
    if (searchText !== '') {
      dispatch(
        getUnassignedBatchesData(
          {
            jobDetails: searchText.trim(),
          },
          navigate
        )
      );
    } else {
      dispatch(
        getUnassignedBatchesData(
          {
            productionStartDate: isoStartFormat(new Date()),
            productionEndDate: isoEndFormat(new Date()),
          },
          navigate
        )
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setIsSearchingTrue());
    dispatch(
      setOrdersListData(
        {
          sortBy: -1,
          deliveryDate: formatDateTime(deliveryDate),
          floristId: -1,
          searchKey: searchText,
        },
        -1,
        navigate
      )
    );
  };

  const handleOrdersSubmit = (e) => {
    e.preventDefault();
    dispatch(
      setOrdersListData(
        { ...params, page: 1, floristId: -1, searchKey: searchText, isFlorist: true },
        params.slotId,
        navigate
      )
    );
  };

  return (
    <form className="search-form" onSubmit={(e) => getHandler(currentPage, e)}>
      <input
        id="search-header-input"
        placeholder={placeholderHandler(currentPage)}
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
      />
      <SearchIcon />
    </form>
  );
};

export default HeaderSearch;
