/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Tabs = ({ children }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== '/' && location.pathname.split('/')[1] !== 'login') {
      setCurrentActiveTab(location.pathname.indexOf('production') > 0 ? 1 : 0);
    }
  }, [location.pathname]);

  const onChangeTab = (index, url) => {
    setCurrentActiveTab(index);
    navigate(url);
  };

  return (
    <div className="tabs">
      <div className="labels-wrapper">
        {children.map((el, i) => {
          return (
            <div
              className={'tab-label' + (currentActiveTab === i ? ' active-tab-label' : '')}
              key={i}
              onClick={() => onChangeTab(i, el.props.url)}
            >
              {el.props.label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
