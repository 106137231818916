import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// Components
import Florists from 'components/orders/Florists';
import OrdersList from 'components/orders/OrdersList';
import LoadingOverlay from 'components/common/LoadingOverlay';
// Helpers
import {
  convertQueryParamsIntoObject,
  convertObjectIntoQueryParams,
  formatDateTime,
} from 'constants/Helpers';
import { useOnScreen } from 'components/common/hooks/useOnScreen';
// Selectors
import { getIsLoaderOpen } from 'store/app/AppSelectors';
import {
  getDeliveryData,
  getorderDetailsData,
  getPageCount,
  getDeliveryTimeSlot,
} from 'store/orders/OrdersListSelectors';
// API
import OrdersListService from 'services/OrdersListService';
// Actions
import { setLoaderOpenTrue, setLoaderOpenFalse } from 'store/app/AppActions';

const OrdersPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const ref = useRef();
  const ordersListData = useSelector((state) => getorderDetailsData({ state }));
  const deliveryDate = useSelector((state) => getDeliveryData({ state }));
  const deliveryTimeSlot = useSelector((state) => getDeliveryTimeSlot({ state }));
  const pageCount = useSelector((state) => getPageCount({ state }));
  const [counter, setCounter] = useState(1);
  const isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state }));
  const queryObj = location.search ? convertQueryParamsIntoObject(location.search) : {};
  const onScreen = useOnScreen(ref, '200px');
  const [initialParams, setInitialParams] = useState({
    page: 1,
    deliveryDate: formatDateTime(deliveryDate),
    floristId: -1,
    sorty: 3,
    timeName: deliveryTimeSlot,
  });

  const fetchMoreData = async (params, page) => {
    let clonedParams = { ...params, page: counter };
    if (page) {
      setCounter(1);
      clonedParams = { ...params, page: 1 };
    }

    if (page || counter <= pageCount) {
      dispatch(setLoaderOpenTrue());
      try {
        const res = await OrdersListService.getordersListData(clonedParams, clonedParams.slotId);
        dispatch({
          type: 'SET_ORDERS_LIST_DATA',
          payload: {
            ordersList: res?.data?.data,
            pageCount: res?.data?.pageCount,
            isFlorist: page ? true : false,
          },
        });
        navigate({
          pathname: '/orders',
          search: convertObjectIntoQueryParams(clonedParams),
        });
        setCounter((prev) => prev + 1);
        dispatch(setLoaderOpenFalse());
      } catch (err) {
        dispatch(setLoaderOpenFalse());
      }
    }
  };

  const fetchData = (page) => {
    setInitialParams(queryObj);
    return fetchMoreData(queryObj, page);
  };

  useEffect(() => {
    fetchData(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      <Florists initialParams={initialParams} setCounter={setCounter} slotId={queryObj.slotId} />
      <OrdersList
        ordersListData={ordersListData}
        fetchMoreData={fetchData}
        initialParams={initialParams}
        onScreen={onScreen}
      />
      <div ref={ref} />
    </>
  );
};

export default OrdersPage;
