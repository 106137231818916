import cloneDeep from 'lodash/cloneDeep';
import { toast } from 'react-toastify';
import AuthService from 'services/AuthService';
//api
import NotificationsService from 'services/NotificationsService';
//selectors
import { getNotificationsList } from './AppSelectors';

export const setIsLoggedInTrue = () => ({ type: 'SET_IS_LOGGED_IN_TRUE' });
export const setIsLoggedInFalse = () => ({ type: 'SET_IS_LOGGED_IN_FALSE' });

export const setLoaderOpenTrue = () => ({ type: 'SET_LOADER_OPEN_TRUE' });
export const setLoaderOpenFalse = () => ({ type: 'SET_LOADER_OPEN_FALSE' });

export const setNotificationsList = () => async (dispatch) => {
  try {
    const res = await NotificationsService.getNotificationsList();
    const notificationsLength = res.data.data.filter((el) => el.read === false).length;
    dispatch({
      type: 'SET_NOTIFICATIONS_LIST',
      payload: res.data.data,
    });
    dispatch({
      type: 'SET_NOTIFICATIONS_LENGTH',
      payload: notificationsLength,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setReadNotification = (id) => async (dispatch, getState) => {
  const state = getState(),
    notificationsList = cloneDeep(getNotificationsList({ state }));
  try {
    await NotificationsService.getReadNotification(id);
    notificationsList.find((item) => item.id === id).read = true;
    const notificationsLength = notificationsList.filter((el) => el.read === false).length;
    dispatch({
      type: 'SET_NOTIFICATIONS_LIST',
      payload: notificationsList,
    });
    dispatch({
      type: 'SET_NOTIFICATIONS_LENGTH',
      payload: notificationsLength,
    });
  } catch (e) {
    console.log(e);
  }
};

export const setToggleNotificationAlert = () => async (dispatch) => {
  dispatch({
    type: 'SET_TOGGLE_NOTIFICATIONS_ALERT',
  });
};

export const logoutFlorist = () => async (dispatch) => {
  dispatch({ type: 'SET_IS_LOGGED_OUT_CLICKED' });
};

export const getPermissions = () => async (dispatch) => {
  try {
    const res = await AuthService.getPermissions();
    dispatch({
      type: 'SET_PERMISSIONS',
      payload: res.data,
    });
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.message ?? 'Failed to fetch permissions!');
  }
};

export const setIsHeadFloristTrue = () => ({ type: 'SET_IS_HEAD_FLORIST_TRUE' });
export const setIsHeadFloristFalse = () => ({ type: 'SET_IS_HEAD_FLORIST_FALSE' });

export const setFirebasePermissions = (data) => async (dispatch) => {
  try {
    dispatch({
      type: 'SET_FIREBASE_PERMISSIONS',
      payload: data,
    });
  } catch (e) {
    console.log(e);
    toast.error(e?.response?.data?.message ?? 'Failed to set firebase permissions!');
  }
};
