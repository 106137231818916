import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getFromLocalStorage } from 'helpers/LocalStorage';
// Components
import OrderDetailsCard from 'headflorist/components/orderDetails/OrderDetailsCard';
import CartDetailsCard from 'headflorist/components/orderDetails/CartDetailsCard';
import { default as CartDetails } from 'headflorist/components/orderDetails/noDeviationComponents/CartDetailsCard';
import { default as OrderDetails } from 'headflorist/components/orderDetails/noDeviationComponents/OrderDetailsCard';
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
// Actions
import { setOrderDetailsAllData } from 'headflorist/store/orderDetails/OrderDetailsActions';
// Selectors
import {
  getOrderDetails,
  getCartDetails,
} from 'headflorist/store/orderDetails/OrderDetailsSelectors';
import { getIsLoaderOpen } from 'headflorist/store/app/AppSelectors';
import { getFirebasePermissions } from 'store/app/AppSelectors';

const HFOrderDetailsPage = () => {
  const [hasSubmittedBefore, setHasSubmittedBefore] = useState(false),
    [hasDeviation, setHasDeviation] = useState([]),
    orderDetails = useSelector((state) => getOrderDetails({ state })),
    cartDetails = useSelector((state) => getCartDetails({ state })),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    firebasePermissions = useSelector((state) => getFirebasePermissions({ state })),
    opsID = getFromLocalStorage('opsId'),
    deviationEnabled = firebasePermissions?.enable_deviation?.filter(
      (ops) => ops.id === parseInt(opsID)
    )?.[0]?.enable,
    params = useParams(),
    dispatch = useDispatch();

  const fetchOrderDetails = (orderId) => {
    dispatch(setOrderDetailsAllData(orderId));
  };

  useEffect(() => {
    if (cartDetails && cartDetails?.length > 0) {
      let deviationArr = [];
      let submittedArr = [];
      cartDetails.forEach((c) => {
        deviationArr.push({
          productId: c?.productId,
          hasDeviation: false,
          hasEditDeviation: false,
          cartId: c?.id,
        });
        submittedArr.push({ productId: c?.productId, hasSubmittedBefore: false, cartId: c?.id });
      });
      setHasDeviation(deviationArr);
      setHasSubmittedBefore(submittedArr);
    }
  }, [cartDetails]);

  useEffect(() => {
    fetchOrderDetails(params.orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      {!isLoaderOpen && (
        <>
          {deviationEnabled ? (
            <OrderDetailsCard
              orderDetails={orderDetails}
              hasSubmittedBefore={hasSubmittedBefore}
              hasDeviation={hasDeviation}
            />
          ) : (
            <OrderDetails orderDetails={orderDetails} />
          )}
          {cartDetails !== '' && cartDetails?.length > 0 && (
            <div className={`orderDetails-cart-wrapper ${deviationEnabled ? '' : 'no-deviation'}`}>
              {cartDetails?.map((item) =>
                deviationEnabled ? (
                  <CartDetailsCard
                    key={`item-key-${item?.productId}`}
                    cartItem={item}
                    orderMapId={orderDetails?.orderMapId}
                    preparationStatus={orderDetails?.preparationStatus}
                    hasSubmittedBefore={hasSubmittedBefore}
                    hasDeviation={hasDeviation}
                    setHasSubmittedBefore={setHasSubmittedBefore}
                    setHasDeviation={setHasDeviation}
                  />
                ) : (
                  <CartDetails
                    key={`item-key-${item?.productId}`}
                    cartItem={item}
                    orderMapId={orderDetails?.orderMapId}
                  />
                )
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HFOrderDetailsPage;
