import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
// Components
import DatePicker from 'components/common/DatePicker';
import LoadingOverlay from 'components/common/LoadingOverlay';
import StarIcon from 'assets/images/svg/StarIcon';
import DownArrow from 'assets/images/svg/DownArrow';
import RightCaret from 'assets/images/svg/RightCaret';
import CustomButton from 'components/common/CustomButton';
// Helpers
import {
  formatDateForInput,
  convertObjectIntoQueryParams,
  formatDateTime,
} from 'constants/Helpers';
// API
import TimeSlotsService from 'services/TimeSlotsService';
// Selectors
import { getDeliveryData } from 'store/orders/OrdersListSelectors';

const HomePage = () => {
  const [showSingleDatePicker, setShowSingleDatePicker] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]),
    [showLoader, setShowLoader] = useState(false),
    deliveryDate = useSelector((state) => getDeliveryData({ state })),
    dispatch = useDispatch(),
    navigate = useNavigate();

  const fetchTimeSlots = async (date) => {
    const params = {
      deliveryDate: formatDateForInput(date),
    };
    setShowLoader(true);
    try {
      const res = await TimeSlotsService.getTimeSlotsByDate(params);
      setTimeSlots(res.data[0].slots);
      setShowLoader(false);
    } catch (err) {
      setTimeSlots([]);
      setShowLoader(false);
    }
  };

  const handleDeliveryDate = (e) => {
    fetchTimeSlots(moment(e));
    dispatch({
      type: 'SET_DELIVERY_DATE',
      payload: new Date(e),
    });
  };

  const handleOrdersListPage = (item) => {
    dispatch({
      type: 'SET_DELIVERY_TIME_SLOT',
      payload: `${item.deliveryTimeName} (${item.nickName})`,
    });
    navigate({
      pathname: `/orders`,
      search: convertObjectIntoQueryParams({
        slotId: item.slotId,
        sortby: 3,
        deliveryDate: formatDateTime(deliveryDate),
        floristId: -1,
        searchKey: '',
      }),
    });
  };

  useEffect(() => {
    fetchTimeSlots(deliveryDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showLoader && <LoadingOverlay />}
      {!showLoader && (
        <div
          className="home-deliverydate-wrapper card"
          onClick={() => setShowSingleDatePicker(true)}
        >
          <p>Delivery date</p>
          <div className="delivery-arrow-wrapper">
            <DownArrow customCss={showSingleDatePicker ? 'upside-down' : ''} />
          </div>
          <DatePicker
            id="home-deliverydate"
            label=""
            value={deliveryDate.toISOString()}
            showSingleDatePicker={showSingleDatePicker}
            setShowSingleDatePicker={setShowSingleDatePicker}
            handleChange={handleDeliveryDate}
            customProps={{
              minDate: new Date(),
              maxDate: new Date('2030'),
            }}
          />
        </div>
      )}

      {timeSlots.length > 0 && !showLoader && (
        <div className="card-list-wrapper">
          {timeSlots.map((item, i) => (
            <div className="card timeslot-card" onClick={() => handleOrdersListPage(item)} key={i}>
              <div className="card-column">
                <div className="card-header">
                  <p>
                    {item.deliveryTimeName} ({item.nickName.toUpperCase()}){' '}
                    {item.productionLine === 1 && <StarIcon cssClass="star-icon" />}
                  </p>
                </div>
                <div className="card-body">
                  <p>
                    {item.collecting} Collecting - {item.orders} Ordered - {item.preparing}{' '}
                    Preparing - {item.notAssgined} Unassigned
                  </p>
                </div>
              </div>
              <div className="card-column caret">
                <CustomButton
                  id="assign-btn"
                  handleClick={() => handleOrdersListPage(item)}
                  fontAwsome={<RightCaret />}
                  type="rightCaret"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default HomePage;
