import React from 'react';
// Components
import CustomButton from 'components/common/CustomButton';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmationModal = ({
  message,
  handleCancelClick,
  handleProceedClick,
  isOpen,
  setIsOpen,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => setIsOpen((prev) => !prev)}
      className="confirmation-modal-container"
      centered={true}
    >
      <ModalBody className="confirmation-modal">
        <p
          dangerouslySetInnerHTML={{
            __html: message,
          }}
        ></p>
      </ModalBody>
      <ModalFooter className="confirmation-btns">
        <CustomButton id="minus-btn" name="Cancel" handleClick={handleCancelClick} type="cancel" />
        <CustomButton
          id="minus-btn"
          name="Proceed"
          handleClick={handleProceedClick}
          type="proceed"
        />
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
