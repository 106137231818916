import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// Components
import CustomButton from 'headflorist/components/common/CustomButton';
import LoadingOverlay from 'headflorist/components/common/LoadingOverlay';
import WarningIcon from 'headflorist/assets/images/svg/WarningIcon';
import { getDeviationReasons } from 'headflorist/store/orderDetails/OrderDetailsSelectors';
// API
import OrderDetailsService from 'headflorist/services/OrderDetailsService';
// Helpers
import { dataURLtoFile } from 'constants/Helpers';

const ReasonsModal = ({
  isReasonModalOpen,
  setIsReasonModalOpen,
  payload,
  setPayload,
  orderMapId,
  fetchCartDetails,
  cartId,
  isEditDeviation,
  inventoryData,
}) => {
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const deviationReasons = useSelector((state) => getDeviationReasons({ state }));
  const damagedQty = payload?.reduce(
    (accumulator, currentValue) => accumulator + currentValue.damagedQty,
    0
  );
  const exceededQty = payload?.reduce(
    (accumulator, currentValue) =>
      accumulator +
      (currentValue.usedQty > currentValue.qty ? currentValue.usedQty - currentValue.qty : 0),
    0
  );
  const lessQty = payload?.reduce(
    (accumulator, currentValue) =>
      accumulator +
      (currentValue.qty > currentValue.usedQty ? currentValue.qty - currentValue.usedQty : 0),
    0
  );
  let noDeviation = true;
  payload?.forEach((p, idx) => {
    if (
      isEditDeviation
        ? (p?.deviationReason !== 0 && inventoryData?.[idx]?.usedQty !== p?.usedQty) ||
          (inventoryData?.[idx]?.deviationReason === 0 && p.usedQty !== p.qty)
        : p.usedQty !== p.qty
    ) {
      noDeviation = false;
    }
  });

  const checkDisable = (data = payload) => {
    let isDisabled = false;
    data?.forEach((d) => {
      if (
        (d?.usedQty !== d?.qty && d?.deviationReason === 0) ||
        (d?.damagedQty > 0 && d?.damagedReason === 0) ||
        (d?.damagedReason === 99 &&
          (d?.damagedReasonDetails === '' || d?.damagedReasonDetails === null)) ||
        (d?.deviationReason === 99 &&
          (d?.deviationReasonDetails === '' || d?.deviationReasonDetails === null))
      ) {
        isDisabled = true;
      }
    });
    setIsDisable(isDisabled);
  };

  const handleChangeReason = (item, reason, type) => {
    const newData = payload?.map((p) => {
      if (p?.id === item?.id) {
        if (type === 'damaged') {
          p.damagedReason = parseInt(reason?.id);
        } else {
          p.deviationReason = parseInt(reason?.id);
        }
      }
      return p;
    });
    setPayload(newData);
    checkDisable(newData);
  };

  const handleChangeReasonTxt = (e, item, type) => {
    const newData = payload?.map((p) => {
      if (p?.id === item?.id) {
        if (type === 'damaged') {
          p.damagedReasonDetails = e.target.value;
        } else {
          p.deviationReasonDetails = e.target.value;
        }
      }
      return p;
    });
    setPayload(newData);
    checkDisable(newData);
  };

  const handleWarnningMessage = () => {
    let warningMsg = '';
    if (!noDeviation) {
      if (exceededQty > 0) {
        warningMsg += `Exceeded (${exceededQty})`;
      }
      if (lessQty > 0) {
        if (exceededQty > 0) {
          warningMsg += ', ';
        }
        warningMsg += `Less (${lessQty})`;
      }
      if (damagedQty > 0) {
        warningMsg += ', ';
      }
    }
    if (damagedQty > 0) {
      warningMsg += `Damaged (${damagedQty})`;
    }
    return warningMsg;
  };

  const handleUpload = async () => {
    setIsLoaderOpen(true);
    try {
      const formData = new FormData();
      for (let i = 0; i < payload.length; i++) {
        for (const key in payload[i]) {
          if (payload[i][key] !== null) {
            if (key === 'damagedImages') {
              for (const image in payload[i][key]) {
                if (payload[i][key][image]) {
                  formData.append(
                    `floristOrderMapInventoryPayloads[${i}].${key}`,
                    await dataURLtoFile(payload[i][key][image], 'image.png')
                  );
                }
              }
            } else {
              if (!['id', 'name', 'image', 'barcode', 'qty'].includes(key))
                formData.append(`floristOrderMapInventoryPayloads[${i}].${key}`, payload[i][key]);
            }
          }
        }
      }
      const res = await OrderDetailsService.addDeviation(
        formData,
        orderMapId,
        cartId,
        payload?.[0]?.productId
      );
      await fetchCartDetails();
      setIsReasonModalOpen(false);
      toast.success(res?.data?.message || 'Deviation submitted successfully!');
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Something went wrong, please try again!');
      console.log(err);
    } finally {
      setIsLoaderOpen(false);
    }
  };

  const submitEditDeviation = async () => {
    setIsLoaderOpen(true);
    try {
      const formData = new FormData();
      for (let i = 0; i < payload.length; i++) {
        for (const key in payload[i]) {
          if (payload[i][key] !== null) {
            if (key === 'damagedImages') {
              for (const image in payload[i][key]) {
                if (payload[i][key][image]) {
                  formData.append(
                    `floristOrderMapInventoryPayloads[${i}].${key}`,
                    typeof payload[i][key][image] === 'string'
                      ? null
                      : await dataURLtoFile(payload[i][key][image], 'image.png')
                  );
                }
              }
            } else {
              if (!['id', 'name', 'image', 'barcode', 'qty'].includes(key))
                formData.append(`floristOrderMapInventoryPayloads[${i}].${key}`, payload[i][key]);
            }
          }
        }
      }
      const res = await OrderDetailsService.editDeviation(
        formData,
        orderMapId,
        cartId,
        payload?.[0]?.productId
      );
      await fetchCartDetails();
      toast.success(res?.data?.message || 'Edit deviation submitted successfully!');
      setIsReasonModalOpen(false);
    } catch (err) {
      toast.error(err?.response?.data?.message ?? 'Something went wrong, please try again!');
      console.log(err);
    } finally {
      setIsLoaderOpen(false);
    }
  };

  useEffect(() => {
    checkDisable(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="reasons-modal-wrapper">
      {isLoaderOpen && <LoadingOverlay />}
      {isReasonModalOpen && (
        <Modal
          className="reasons-modal"
          isOpen={isReasonModalOpen}
          toggle={() => {
            setIsReasonModalOpen((prev) => !prev);
          }}
          modalClassName="reasons-modal-parent"
        >
          <ModalHeader>
            <div className="reasons-warning-msg">
              {handleWarnningMessage() !== '' && <WarningIcon />} <p>{handleWarnningMessage()}</p>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="reasons-modal-body">
              {!noDeviation && (
                <>
                  <p className="reason-main-title">Select the reason for Deviations</p>
                  {payload?.map((p, idx) => {
                    if (
                      isEditDeviation
                        ? (p?.deviationReason !== 0 &&
                            inventoryData?.[idx]?.usedQty !== p?.usedQty) ||
                          (inventoryData?.[idx]?.deviationReason === 0 && p.usedQty !== p.qty)
                        : p.usedQty !== p.qty
                    ) {
                      return (
                        <div className="reason-card" key={`deviation-div-${p?.id}`}>
                          <div className="reason-item-div">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      style={{ backgroundImage: `url(${p?.image})` }}
                                      className="reason-item-image"
                                      alt="product"
                                    ></div>
                                  </td>
                                  <td>{p?.name}</td>
                                  <td>
                                    <div>
                                      <p>Required Qty</p>
                                      <p>{p?.qty}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      <p>Used Qty</p>
                                      <p>{p?.usedQty}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <ul className="reason-list">
                            {deviationReasons?.deviationReasons?.map((item, idx) => (
                              <li
                                className="reason-item"
                                key={`deviation-reason-${item?.id}-${idx}`}
                              >
                                <div className="custom-radio-container">
                                  <input
                                    type="radio"
                                    id={`reason-deviation-${p?.id}-${idx}`}
                                    name={`reason-deviation-${p?.id}`}
                                    value={item?.reason}
                                    onChange={() => handleChangeReason(p, item, 'deviation')}
                                    checked={parseInt(p?.deviationReason) === parseInt(item?.id)}
                                  />
                                  <div className="custom-radio"></div>
                                </div>
                                <label
                                  htmlFor={`reason-deviation-${p?.id}-${idx}`}
                                  onClick={() => handleChangeReason(p, item, 'deviation')}
                                >
                                  {item?.reason}
                                </label>
                              </li>
                            ))}
                            {p?.deviationReason === 99 && (
                              <input
                                type="text"
                                className="other-input"
                                onChange={(e) => handleChangeReasonTxt(e, p, 'deviation')}
                              />
                            )}
                          </ul>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
              {damagedQty > 0 && (
                <>
                  <p className="reason-main-title">Select the reason for Damge</p>
                  {payload?.map((p) => {
                    if (p.damagedQty > 0) {
                      return (
                        <div className="reason-card" key={`damaged-div-${p?.id}`}>
                          <div className="reason-item-div">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      style={{ backgroundImage: `url(${p?.image})` }}
                                      className="reason-item-image"
                                      alt="product"
                                    ></div>
                                  </td>
                                  <td>{p?.name}</td>
                                  <td>
                                    <div>
                                      <p>Damaged Qty</p>
                                      <p>{p?.damagedQty}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <ul className="reason-list">
                            {deviationReasons?.damageReasons?.map((item) => (
                              <li className="reason-item" key={`damaged-reason-${item?.id}`}>
                                <div className="custom-radio-container">
                                  <input
                                    type="radio"
                                    id={`reason-damaged-${p?.id}`}
                                    name={`reason-damaged-${p?.id}`}
                                    value={item?.reason}
                                    onChange={() => handleChangeReason(p, item, 'damaged')}
                                    checked={parseInt(p?.damagedReason) === parseInt(item?.id)}
                                  />
                                  <div className="custom-radio"></div>
                                </div>
                                <label
                                  htmlFor={`reason-damaged-${p?.id}`}
                                  onClick={() => handleChangeReason(p, item, 'damaged')}
                                >
                                  {item?.reason}
                                </label>
                              </li>
                            ))}
                            {p?.damagedReason === 99 && (
                              <input
                                type="text"
                                className="other-input"
                                onChange={(e) => handleChangeReasonTxt(e, p, 'damaged')}
                              />
                            )}
                          </ul>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
            </div>
            <div className="reason-modal-footer">
              <CustomButton
                id="cancel-reason"
                handleClick={() => {
                  setIsReasonModalOpen(false);
                }}
                type="cancel-reason"
                name="Cancel"
              />
              <CustomButton
                id="proceed-reason"
                handleClick={() => (isEditDeviation ? submitEditDeviation() : handleUpload())}
                type="proceed-reason"
                name="Proceed"
                disabled={isLoaderOpen || isDisable}
              />
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
};

export default ReasonsModal;
