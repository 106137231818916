import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import OrderDetailsCard from 'components/orderDetails/OrderDetailsCard';
import CartDetailsCard from 'components/orderDetails/CartDetailsCard';
import LoadingOverlay from 'components/common/LoadingOverlay';
// Actions
import { setOrderDetailsAllData } from 'store/orderDetails/OrderDetailsActions';
// Selectors
import { getOrderDetails, getCartDetails } from 'store/orderDetails/OrderDetailsSelectors';
import { getIsLoaderOpen } from 'store/app/AppSelectors';
const OrderDetailsPage = () => {
  const orderDetails = useSelector((state) => getOrderDetails({ state })),
    cartDetails = useSelector((state) => getCartDetails({ state })),
    isLoaderOpen = useSelector((state) => getIsLoaderOpen({ state })),
    params = useParams(),
    dispatch = useDispatch();

  const fetchOrderDetails = (orderId) => {
    dispatch(setOrderDetailsAllData(orderId));
  };

  useEffect(() => {
    fetchOrderDetails(params.orderId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoaderOpen && <LoadingOverlay />}
      {!isLoaderOpen && (
        <>
          <OrderDetailsCard orderDetails={orderDetails} />
          {cartDetails !== '' && cartDetails?.length > 0 && (
            <div className="orderDetails-cart-wrapper">
              {cartDetails?.map((item, i) => (
                <CartDetailsCard cartItem={item} />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderDetailsPage;
