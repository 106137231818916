import { useSelector } from 'react-redux';
// Selectors
import { getHeadFloristEnabled } from 'store/app/AppSelectors';
// pages
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import OrdersPage from 'pages/OrdersPage';
import OrderDetailsPage from 'pages/OrderDetailsPage';
import CompletedOrdersPage from 'pages/CompletedOrdersPage';
import GroupedSkuPage from 'pages/GroupedSkuPage';
import GroupedZonePage from 'pages/GroupedZonePage';
import ProductionHomePage from 'pages/production/ProductionHomePage';

// Head of Florists pages
import HFHomePage from 'headflorist/pages/HFHomePage';
import HFOrdersPage from 'headflorist/pages/HFOrdersPage';
import HFOrderDetailsPage from 'headflorist/pages/HFOrderDetailsPage';
import HFCompletedOrdersPage from 'headflorist/pages/HFCompletedOrdersPage';
import HFProductionHomePage from 'headflorist/pages/production/HFProductionHomePage';
import HFMyTasksPage from 'headflorist/pages/HFMyTasksPage';
import HFQualityCheckPage from 'headflorist/pages/HFQualityCheckPage';

// urls
import {
  getHomePageUrl,
  getLoginPageUrl,
  getOrdersPageUrl,
  getOrderDetailsPageUrl,
  getCompletedOrdersPageUrl,
  getGroupedSkuPageUrl,
  getGroupedZonePageUrl,
  getProductionHomePage,
  getMyTasksPageUrl,
  getQualityCheckPageUrl,
} from 'constants/AppUrls';

export const registerationRoutes = [{ path: getLoginPageUrl(), component: LoginPage }];

export const RestrictedRoutes = () => {
  const headFloristEnabled = useSelector((state) => getHeadFloristEnabled({ state }));
  return [
    {
      path: getHomePageUrl(),
      component: headFloristEnabled ? HFHomePage : HomePage,
      exact: true,
    },
    {
      path: getOrdersPageUrl(),
      component: headFloristEnabled ? HFOrdersPage : OrdersPage,
      exact: true,
    },
    {
      path: getOrderDetailsPageUrl(),
      component: headFloristEnabled ? HFOrderDetailsPage : OrderDetailsPage,
      exact: true,
    },
    {
      path: getCompletedOrdersPageUrl(),
      component: headFloristEnabled ? HFCompletedOrdersPage : CompletedOrdersPage,
      exact: true,
    },
    {
      path: getGroupedSkuPageUrl(),
      component: headFloristEnabled ? HFOrdersPage : GroupedSkuPage,
      exact: true,
    },
    {
      path: getGroupedZonePageUrl(),
      component: headFloristEnabled ? HFOrdersPage : GroupedZonePage,
      exact: true,
    },
    {
      path: getProductionHomePage(),
      component: headFloristEnabled ? HFProductionHomePage : ProductionHomePage,
      exact: true,
    },
    {
      path: getMyTasksPageUrl(),
      component: HFMyTasksPage,
      exact: true,
    },
    {
      path: getQualityCheckPageUrl(),
      component: HFQualityCheckPage,
      exact: true,
    },
  ];
};
