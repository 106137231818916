import { updateObject } from '../Utility';

const initialState = {
  orderDetails: '',
  cartDetails: '',
  inventoryDetails: '',
  deviationReasons: [],
  qcImages: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ORDER_DETAILS': {
      return updateObject(state, {
        orderDetails: action.payload,
      });
    }
    case 'SET_CART_DETAILS': {
      return updateObject(state, {
        cartDetails: action.payload,
      });
    }
    case 'SET_INVENTORY_DETAILS': {
      return updateObject(state, {
        inventoryDetails: action.payload,
      });
    }
    case 'SET_DEVIATION_REASONS': {
      return updateObject(state, {
        deviationReasons: action.payload,
      });
    }

    case 'SET_QC_IMAGES': {
      return updateObject(state, {
        qcImages: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
