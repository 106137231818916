import React, { useState } from 'react';
import { Slide } from 'react-slideshow-image';
import Lightbox from 'react-image-lightbox';

const ImagesList = ({ listOfImages }) => {
  const [isLightBox, setIsLightBox] = useState(false),
    [clickedImg, setClickedImg] = useState('');
  return (
    <div className="slide-container">
      {listOfImages && (
        <Slide>
          {listOfImages?.map((el, n) => (
            <div key={n} className="each-slide">
              <img
                onClick={() => {
                  setClickedImg(el?.image);
                  setIsLightBox(true);
                }}
                className="productDetails-image"
                src={el?.image}
                alt="product"
              />
            </div>
          ))}
        </Slide>
      )}
      {isLightBox && <Lightbox mainSrc={clickedImg} onCloseRequest={() => setIsLightBox(false)} />}
    </div>
  );
};

export default ImagesList;
