import React, { useState, useEffect } from 'react';
// import { dateFormat } from 'constants/Helpers';
import moment from 'moment';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { DateRangePicker } from 'react-date-range';
import CustomInput from './CustomInput';
import CustomButton from './CustomButton';

const DateRange = ({
  id,
  label,
  showDateRange,
  setShowDateRange,
  value,
  handleChange,
  fetchData,
}) => {
  const handleLeftAmount = () => {
    if (window.screen.width < 500) {
      return 0;
    } else {
      return '9%';
    }
  };

  const [isRight, setIsRight] = useState(false);

  useEffect(() => {
    setIsRight(
      (
        (document.getElementById('date-range-picker')?.getBoundingClientRect().left * 100) /
        window.innerWidth
      ).toFixed(0) > 44
    );
  }, []);

  return (
    <div className="form-group filter-select">
      <label className="font-weight-bold" htmlFor={id}>
        {label}
      </label>
      <div className="single-filter-date">
        <CustomInput
          id={id}
          customCss="form-control digits"
          value={`${moment(new Date(value.startDate)).format('MMM DD, YYYY')} - ${moment(
            new Date(value.endDate)
          ).format('MMM DD, YYYY')}`}
          handleClick={() => setShowDateRange(true)}
          readOnly
        />
      </div>
      {showDateRange && (
        <ClickAwayListener onClickAway={() => setShowDateRange(false)}>
          <div
            id="date-range-picker"
            onClickAway={() => setShowDateRange(false)}
            className={isRight ? 'right' : ''}
          >
            <DateRangePicker
              ranges={[value]}
              months={2}
              onChange={(item) => handleChange(item)}
              direction={window.screen.width < 500 ? 'vertical' : 'horizontal'}
              moveRangeOnFirstSelection={false}
            />
            <CustomButton
              style={{
                left: handleLeftAmount(),
                bottom: window.screen.width < 500 ? 0 : 10,
              }}
              id="production-daterange-apply"
              name="Apply"
              handleClick={() => {
                setShowDateRange(false);
                fetchData();
              }}
              type="daterange-apply"
            />
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default DateRange;
