export const getDeliveryData = ({ state }) => state.ordersList.deliveryDate;
export const getDeliveryTimeSlot = ({ state }) => state.ordersList.deliveryTimeSlot;

export const getorderDetailsData = ({ state }) => state.ordersList.ordersListData;

export const getPageCount = ({ state }) => state.ordersList.pageCount;

export const getOrdersIsSearching = ({ state }) => state.ordersList.isSearching;

export const getGroupBySkuData = ({ state }) => state.ordersList.groupBySku.data;
export const getGroupBySkuCurrentPage = ({ state }) => state.ordersList.groupBySku.currentPage;
export const getGroupBySkuPageCount = ({ state }) => state.ordersList.groupBySku.pageCount;

export const getGroupByZoneData = ({ state }) => state.ordersList.groupByZone.data;
export const getGroupByZoneCurrentPage = ({ state }) => state.ordersList.groupByZone.currentPage;
export const getGroupByZonePageCount = ({ state }) => state.ordersList.groupByZone.pageCount;

export const getFloristsList = ({ state }) => state.ordersList.floristsList;
