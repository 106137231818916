// import React from "react";
import { useSelector } from 'react-redux';
// constant
import { hasPermission } from 'constants/Helpers';
// selectors
import { getPermissionsData } from 'store/app/AppSelectors';

const RestrictedSection = ({ action, page, children }) => {
    const permissionsList = useSelector((state) => getPermissionsData({ state }));
    if (hasPermission(permissionsList, page, action)) return children;
    return null;
};

export default RestrictedSection;
