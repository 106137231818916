import React from 'react';

const LogoutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="svgicon" viewBox="0 0 512 512">
    <title>Log Out</title>
    <path
      d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="svgicon-fill-none svgicon-stroke-width"
    />
  </svg>
);

export default LogoutIcon;
