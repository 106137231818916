import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from './CustomButton';
import NotificationIcon from 'headflorist/assets/images/svg/NotificationIcon';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { convertToDateAndTime } from 'constants/Helpers';
import ConfirmationModal from 'headflorist/components/common/ConfirmationModal';
//action
import {
  setReadNotification,
  setNotificationsList,
  setProceedAckMesaage,
} from 'headflorist/store/app/AppActions';
//selectors
import { getNotificationsList, getNotificationsLength } from 'headflorist/store/app/AppSelectors';

const NotificationButton = () => {
  const notificationsList = useSelector((state) => getNotificationsList({ state })),
    notificationsLength = useSelector((state) => getNotificationsLength({ state })),
    [showPopover, setShowPopover] = useState(false),
    [isUnread, setIsUnread] = useState(false),
    [isModalOpen, setIsModalOpen] = useState(false),
    [ackMessage, setAckMessage] = useState(''),
    [mapId, setMapId] = useState(''),
    [notificationId, setNotificationId] = useState(''),
    [isBtnDisabled, setIsBtnDisabled] = useState(false),
    dispatch = useDispatch();

  const handleReadNotification = (item) => {
    if (item?.read === false) {
      dispatch(setReadNotification(item?.id));
    }
  };

  const fetchNotificationsList = () => {
    setIsUnread(false);
    dispatch(setNotificationsList());
  };

  const filterUnreadNotifications = () => {
    setIsUnread(true);
    const filteredNotifications = notificationsList?.filter((el) => !el?.read);
    dispatch({
      type: 'SET_NOTIFICATIONS_LIST',
      payload: filteredNotifications ?? [],
    });
  };

  useEffect(() => {
    fetchNotificationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProceedAck = (item) => {
    setAckMessage(item?.content);
    setMapId(item?.mapId);
    setNotificationId(item?.id);
    setIsModalOpen(true);
  };

  const handleAckRequest = (type) => {
    dispatch(
      setProceedAckMesaage(
        type === 'proceed' ? notificationId : mapId,
        setIsBtnDisabled,
        setIsModalOpen,
        type
      )
    );
  };

  return (
    <div className="notification-btn-wrapper">
      {isModalOpen && (
        <ConfirmationModal
          handleCancelClick={() => handleAckRequest('reject')}
          handleProceedClick={() => handleAckRequest('proceed')}
          message={ackMessage}
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          isDisabledBtn={isBtnDisabled}
          cancelBtnName="Reject"
        />
      )}
      {showPopover && (
        <Modal
          className="notification-modal"
          isOpen={showPopover}
          toggle={() => setShowPopover((prev) => !prev)}
        >
          <ModalHeader close={<></>} toggle={() => setShowPopover((prev) => !prev)}>
            Notifications
          </ModalHeader>
          <div className="read-notfication">
            <CustomButton
              id="assign-btn"
              name="All"
              type="assign"
              cssClasses={`read-notification-btn ${
                isUnread ? 'all-unread-style' : 'all-read-style'
              } `}
              handleClick={() => fetchNotificationsList()}
            />
            <CustomButton
              id="assign-btn"
              name="Unread"
              type="assign"
              cssClasses={`read-notification-btn ${isUnread ? 'unread-style' : 'read-style'} `}
              handleClick={() => filterUnreadNotifications()}
            />
          </div>
          <ModalBody>
            {notificationsList?.length > 0 ? (
              <>
                {notificationsList.map((item) => (
                  <div
                    onClick={() => handleReadNotification(item)}
                    key={item?.id}
                    className={`notification-text ${item?.read ? '' : 'read-bg-color'}`}
                  >
                    <a href={`/orderdetails/${item?.orderId}`}>Order# {item?.orderId}</a>
                    <div className="content-style">
                      <div>{item?.content}</div>
                      <p>{convertToDateAndTime(item?.createdAt)}</p>
                    </div>
                    <CustomButton
                      id="assign-btn"
                      name="Submit"
                      type="assign"
                      cssClasses="submit-notification"
                      handleClick={() => handleProceedAck(item)}
                    />
                  </div>
                ))}
              </>
            ) : (
              <p>No notifications to show</p>
            )}
          </ModalBody>
        </Modal>
      )}
      <CustomButton
        id="header-notifications"
        handleClick={() => setShowPopover((prev) => !prev)}
        type="header-icon"
        fontAwsome={<NotificationIcon />}
      />
      {notificationsList.length >= 0 && (
        <div onClick={() => setShowPopover((prev) => !prev)} className="notification-badge">
          <span>{notificationsLength}</span>
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
