const HOME = '/home';
const LOGIN = '/login';
const ORDERS = '/orders';
const ORDERDETAILS = '/orderdetails/:orderId';
const COMPLETEDORDERS = '/completed-orders';
const GROUPEDSKU = '/groupedBySKU';
const GROUPEDZONE = '/groupedByZone';
const PRODUCTIONHOMEPAGE = '/production';
const MYTASKPAGE = '/my-task';
const QUALITYCHECK = '/qualitycheck/:orderId';

// Orders
export const getHomePageUrl = () => HOME;
export const getLoginPageUrl = () => LOGIN;
export const getOrdersPageUrl = () => ORDERS;
export const getOrderDetailsPageUrl = () => ORDERDETAILS;
export const getCompletedOrdersPageUrl = () => COMPLETEDORDERS;
export const getGroupedSkuPageUrl = () => GROUPEDSKU;
export const getGroupedZonePageUrl = () => GROUPEDZONE;

// Production
export const getProductionHomePage = () => PRODUCTIONHOMEPAGE;

// Head of Florists
export const getMyTasksPageUrl = () => MYTASKPAGE;
export const getQualityCheckPageUrl = () => QUALITYCHECK;
