import axios from 'axios';
import { getFromLocalStorage } from 'helpers/LocalStorage';
import { store } from 'store';
import { toast } from 'react-toastify';
// actions
import { setIsLoggedInFalse } from 'store/app/AppActions';

const createNewBaseUrl = (url) =>
  axios.create({
    baseURL: url,
  });

const apiQuerytaskService = createNewBaseUrl(process.env.REACT_APP_QUERYTASK),
  apiGatewayService = createNewBaseUrl(process.env.REACT_APP_API_GATEWAY);

const requestInterceptor = (config) => {
  config.headers['opsId'] = getFromLocalStorage('opsId');
  config.headers['Authorization'] = `Bearer ${getFromLocalStorage('token')}`;

  return config;
};

const requestInterceptorError = (error) => {
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  return response;
};

const responseInterceptorError = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      toast.error('401 Unauthorized');
      store.dispatch(setIsLoggedInFalse());
    }
  }

  return Promise.reject(error);
};

const addInterceptors = (service) => {
  service.interceptors.request.use(requestInterceptor, requestInterceptorError);
  service.interceptors.response.use(responseInterceptor, responseInterceptorError);
};

addInterceptors(apiQuerytaskService);
addInterceptors(apiGatewayService);

export { apiQuerytaskService, apiGatewayService };
