import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './RootReducer';
// middlewares
import thunk from 'redux-thunk';

const isDevelopment = process.env.NODE_ENV === 'development';

/* Create a Redux store that holds the app state. */
const configureStore = () => {
  const apply = applyMiddleware(thunk);

  return createStore(
    rootReducer,
    /* preloadedState, */
    // use redux dev tool in development only
    isDevelopment ? composeWithDevTools(apply) : apply
  );
};

export default configureStore;
