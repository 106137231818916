import React, { Fragment, useEffect, useState } from 'react';
import InventoryTable from './InventoryTable';
import ImagesList from './ImagesList';
// API
import OrderDetailsService from 'headflorist/services/OrderDetailsService';

const ProductDetails = ({ cartItem, orderMapId }) => {
  const [inventoryDetails, setInventoryDetails] = useState([]);
  const fetchCartDetails = async () => {
    try {
      const res = await OrderDetailsService.getInventoryDetails(
        orderMapId,
        cartItem?.productId,
        cartItem?.id
      );
      setInventoryDetails(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchCartDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="productDetails-left-col-no-deviation">
        <ImagesList listOfImages={inventoryDetails?.productImages} />
      </div>
      <div className="productDetails-right-col-no-deviation">
        <InventoryTable inventoryData={inventoryDetails?.inventoryDto} />
      </div>
    </Fragment>
  );
};

export default ProductDetails;
