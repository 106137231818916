import React from 'react';

const WarningIcon = ({ cssClass }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="heroicons-mini/exclaimation-circle">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3996 7.99961C14.3996 11.5342 11.5342 14.3996 7.99961 14.3996C4.46499 14.3996 1.59961 11.5342 1.59961 7.99961C1.59961 4.46499 4.46499 1.59961 7.99961 1.59961C11.5342 1.59961 14.3996 4.46499 14.3996 7.99961ZM7.99961 3.99961C8.33098 3.99961 8.59961 4.26824 8.59961 4.59961V8.19961C8.59961 8.53098 8.33098 8.79961 7.99961 8.79961C7.66824 8.79961 7.39961 8.53098 7.39961 8.19961V4.59961C7.39961 4.26824 7.66824 3.99961 7.99961 3.99961ZM7.99961 11.9996C8.44144 11.9996 8.79961 11.6414 8.79961 11.1996C8.79961 10.7578 8.44144 10.3996 7.99961 10.3996C7.55778 10.3996 7.19961 10.7578 7.19961 11.1996C7.19961 11.6414 7.55778 11.9996 7.99961 11.9996Z"
        fill="#F4AA24"
      />
    </g>
  </svg>
);
export default WarningIcon;
